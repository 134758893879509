import * as React from "react";

// Utilitaire pour fusionner les classes CSS
const cn = (...classes: Array<string | boolean | undefined>): string => {
  return classes.filter(Boolean).join(" ");
};

// Types des propriétés
interface CardProps extends React.HTMLAttributes<HTMLDivElement> {
  children: React.ReactNode;
  className?: string;
}

interface CardHeaderProps extends React.HTMLAttributes<HTMLDivElement> {
  children: React.ReactNode;
  className?: string;
}

interface CardTitleProps extends React.HTMLAttributes<HTMLHeadingElement> {
  children: React.ReactNode;
  className?: string;
}

interface CardContentProps extends React.HTMLAttributes<HTMLDivElement> {
  children: React.ReactNode;
  className?: string;
}

// Composant Card principal
const Card = React.forwardRef<HTMLDivElement, CardProps>(
  ({ className, children, ...props }, ref) => (
    <div
      ref={ref}
      className={cn(
        "rounded-xl border border-gray-200 bg-white text-gray-950 shadow-sm transition-shadow hover:shadow-md",
        className
      )}
      {...props}
    >
      {children}
    </div>
  )
);
Card.displayName = "Card";

// Composant CardHeader
const CardHeader = React.forwardRef<HTMLDivElement, CardHeaderProps>(
  ({ className, children, ...props }, ref) => (
    <div
      ref={ref}
      className={cn("flex flex-col space-y-1.5 p-6", className)}
      {...props}
    >
      {children}
    </div>
  )
);
CardHeader.displayName = "CardHeader";

// Composant CardTitle
const CardTitle = React.forwardRef<HTMLHeadingElement, CardTitleProps>(
  ({ className, children, ...props }, ref) => (
    <h3
      ref={ref}
      className={cn(
        "text-2xl font-semibold leading-none tracking-tight text-[#333697]",
        className
      )}
      {...props}
    >
      {children}
    </h3>
  )
);
CardTitle.displayName = "CardTitle";

// Composant CardContent
const CardContent = React.forwardRef<HTMLDivElement, CardContentProps>(
  ({ className, children, ...props }, ref) => (
    <div
      ref={ref}
      className={cn("p-6 pt-0", className)}
      {...props}
    >
      {children}
    </div>
  )
);
CardContent.displayName = "CardContent";

// Composant de démo avec différentes variantes de Card
const CardDemo: React.FC = () => {
  return (
    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 p-6">
      {/* Card basique */}
      <Card>
        <CardHeader>
          <CardTitle>Card Basique</CardTitle>
        </CardHeader>
        <CardContent>
          <p>Contenu de la card basique avec du texte simple.</p>
        </CardContent>
      </Card>

      {/* Card avec image */}
      <Card className="overflow-hidden">
        <img 
          src="/api/placeholder/400/200" 
          alt="placeholder" 
          className="w-full h-48 object-cover"
        />
        <CardHeader>
          <CardTitle>Card avec Image</CardTitle>
        </CardHeader>
        <CardContent>
          <p>Card avec une image en en-tête et du contenu en dessous.</p>
        </CardContent>
      </Card>

      {/* Card interactive */}
      <Card className="cursor-pointer hover:scale-105 transition-transform">
        <CardHeader>
          <CardTitle>Card Interactive</CardTitle>
        </CardHeader>
        <CardContent>
          <p>Cette card réagit au survol avec une animation.</p>
        </CardContent>
      </Card>

      {/* Card avec gradient */}
      <Card className="bg-gradient-to-br from-[#333697] to-[#FC9723] text-white">
        <CardHeader>
          <CardTitle className="text-white">Card avec Gradient</CardTitle>
        </CardHeader>
        <CardContent>
          <p>Card avec un arrière-plan en dégradé et texte blanc.</p>
        </CardContent>
      </Card>

      {/* Card avec bordure accentuée */}
      <Card className="border-l-4 border-l-[#FC9723]">
        <CardHeader>
          <CardTitle>Card avec Bordure</CardTitle>
        </CardHeader>
        <CardContent>
          <p>Card avec une bordure gauche accentuée.</p>
        </CardContent>
      </Card>

      {/* Card avec contenu riche */}
      <Card>
        <CardHeader>
          <CardTitle>Card Riche</CardTitle>
          <div className="flex items-center gap-2 text-sm text-gray-500">
            <span>Par John Doe</span>
            <span>•</span>
            <span>5 min de lecture</span>
          </div>
        </CardHeader>
        <CardContent>
          <p className="mb-4">Contenu principal avec formatage riche.</p>
          <div className="flex items-center justify-between">
            <div className="flex items-center gap-2">
              <img
                src="/api/placeholder/32/32"
                alt="Avatar"
                className="w-8 h-8 rounded-full"
              />
              <span className="text-sm font-medium">John Doe</span>
            </div>
            <button className="px-4 py-2 bg-[#333697] text-white rounded-lg text-sm">
              En savoir plus
            </button>
          </div>
        </CardContent>
      </Card>
    </div>
  );
};

export { Card, CardHeader, CardTitle, CardContent, CardDemo };