import { AxiosResponse } from "axios";
import { RestApi } from "../RestApi";
import { authProvider } from "../../AuthProvider";

export abstract class PrivateApi extends RestApi {
    constructor() {
        super(process.env.REACT_APP_PRIVATE_API!);
    }

    protected async post(path: string, data: any, headers: any = {}): Promise<AxiosResponse> {
        const authorization = authProvider.getTokenDetails()?.idToken;
        if (!authorization) {
            throw Error("missing authorization token")
        }
        return super.post(path, data, {
            ...headers,
            'Authorization': authorization
        });
    }

    protected async get(path: string, headers: any = {}): Promise<AxiosResponse> {
        const authorization = authProvider.getTokenDetails()?.idToken;
        if (!authorization) {
            throw Error("missing authorization token")
        }
        return super.get(path, {
            ...headers,
            'Authorization': authorization
        });
    }

    protected async delete(path: string, headers: any = {}): Promise<AxiosResponse> {
        const authorization = authProvider.getTokenDetails()?.idToken;
        if (!authorization) {
            throw Error("missing authorization token")
        }
        return super.delete(path, {
            ...headers,
            'Authorization': authorization
        });
    }
}
