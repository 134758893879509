import { OnboardingProvider } from "./OnboardingContext";
import OnboardingFlow from "./OnboardingFlow";

export function Signup() {
    return (
        <div>
            <OnboardingProvider>
                <OnboardingFlow />
            </OnboardingProvider>
        </div>
    );
}