import { UserDto } from "../dto/User";
import { OnboardingData } from "../pages/signup/OnboardingContext";
import { authenticationApi } from "./api/public/AuthenticationApi";
import { createUserApi } from "./api/public/CreateUserApi";

class UserService {

    public createUser = async (onboardingData: OnboardingData) => {
        const userDto: UserDto = {
            email: onboardingData.credentials.email,
            firstName: onboardingData.personalInfo.firstName,
            lastName: onboardingData.personalInfo.lastName,
            birthDay: new Date(onboardingData.personalInfo.birthDate),
            address: { country: onboardingData.personalInfo.location, city: onboardingData.personalInfo.location },

            job: onboardingData.profession.title,
            technicalProfile: onboardingData.isTechnical,
            fieldOfActivity: onboardingData.activityDomain,
            withIdea: onboardingData.hasProject ? "YES" : "NO",
            yearsOfExperience: onboardingData.profession.experience,
            projects: []
        }

        const userId = await createUserApi.createUserDb(userDto);
        authenticationApi.signup({
            email: onboardingData.credentials.email,
            password: onboardingData.credentials.password,
            id: userId
        })
        createUserApi.createUserEs(userId);
    }
}

export const userService = new UserService();