import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import {
  ArrowRight, Clock, Tag, Book, FileText, ChartBar, Users, LucideIcon, Search, Filter,
  Bell, BookOpen, Rocket, Target, Brain, Code, Heart, Share2, Bookmark, MessageSquare,
  ThumbsUp, Calendar, TrendingUp, BookMarked
} from 'lucide-react';
import { Card, CardHeader, CardTitle, CardContent } from '../../components/Card';
import { Button } from '../../components/Button';

// Types & Interfaces
interface Author {
  id: string;
  name: string;
  role: string;
  avatar: string;
  company?: string;
  bio?: string;
}

interface PostProps {
  id: string;
  title: string;
  excerpt: string;
  image: string;
  category: string;
  date: string;
  readTime?: string;
  author: Author;
  tags: string[];
  likes: number;
  comments: number;
  isBookmarked?: boolean;
  content?: string;
}

interface CategoryProps {
  id: string;
  name: string;
  icon: LucideIcon;
  count: number;
}

interface CourseProps {
  id: string;
  title: string;
  description: string;
  duration: string;
  modules: number;
  image: string;
  instructor: Author;
  price: number;
  level: 'Débutant' | 'Intermédiaire' | 'Avancé';
}

// Composants Réutilisables
const PostMeta: React.FC<{ date: string; readTime: string; category: string }> = ({
  date,
  readTime,
  category
}) => (
  <div className="flex items-center gap-4 text-sm text-gray-600">
    <span className="flex items-center gap-1">
      <Clock size={14} />
      {readTime}
    </span>
    <span className="flex items-center gap-1">
      <Tag size={14} />
      {category}
    </span>
    <span>{date}</span>
  </div>
);

const SocialActions: React.FC<{
  likes: number;
  comments: number;
  isBookmarked: boolean;
  onLike: () => void;
  onComment: () => void;
  onBookmark: () => void;
  onShare: () => void;
}> = ({ likes, comments, isBookmarked, onLike, onComment, onBookmark, onShare }) => (
  <div className="flex items-center gap-4">
    <button
      onClick={onLike}
      className="flex items-center gap-1 text-gray-600 hover:text-[#FC9723] transition-colors"
    >
      <Heart size={18} />
      <span>{likes}</span>
    </button>
    <button
      onClick={onComment}
      className="flex items-center gap-1 text-gray-600 hover:text-[#FC9723] transition-colors"
    >
      <MessageSquare size={18} />
      <span>{comments}</span>
    </button>
    <button
      onClick={onBookmark}
      className={`${isBookmarked ? 'text-[#333697]' : 'text-gray-600'} hover:text-[#333697] transition-colors`}
    >
      <BookMarked size={18} />
    </button>
    <button
      onClick={onShare}
      className="text-gray-600 hover:text-[#FC9723] transition-colors"
    >
      <Share2 size={18} />
    </button>
  </div>
);

// Composant Article Standard
const BlogPost: React.FC<PostProps & { onInteraction: (type: string) => void }> = ({
  id,
  title,
  excerpt,
  image,
  category,
  date,
  readTime = "5 min",
  author,
  tags,
  likes,
  comments,
  isBookmarked,
  onInteraction
}) => (
  <Card className="overflow-hidden hover:shadow-lg transition-shadow duration-300">
    <Link to={`/blog/${id}`}>
      <img src={image} alt={title} className="w-full h-48 object-cover" />
    </Link>
    <CardContent className="p-6">
      <PostMeta date={date} readTime={readTime} category={category} />
      <Link to={`/blog/${id}`}>
        <h3 className="text-xl font-bold text-[#333697] my-3 hover:text-[#FC9723] transition-colors">
          {title}
        </h3>
      </Link>
      <p className="text-gray-600 mb-4 line-clamp-2">{excerpt}</p>
      <div className="flex items-center justify-between mb-4">
        <Link to={`/author/${author.id}`} className="flex items-center gap-2">
          <img
            src={author.avatar}
            alt={author.name}
            className="w-8 h-8 rounded-full"
          />
          <div>
            <p className="font-medium text-[#333697]">{author.name}</p>
            <p className="text-sm text-gray-600">{author.role}</p>
          </div>
        </Link>
      </div>
      <div className="flex flex-wrap gap-2 mb-4">
        {tags.map((tag) => (
          <Link
            key={tag}
            to={`/tag/${tag}`}
            className="text-sm px-2 py-1 bg-gray-100 rounded-full text-gray-600 hover:bg-[#333697] hover:text-white transition-colors"
          >
            #{tag}
          </Link>
        ))}
      </div>
      {/* <SocialActions
        likes={likes}
        comments={comments}
        isBookmarked={isBookmarked}
        onLike={() => onInteraction('like')}
        onComment={() => onInteraction('comment')}
        onBookmark={() => onInteraction('bookmark')}
        onShare={() => onInteraction('share')}
      /> */}
    </CardContent>
  </Card>
);

// Composant Newsletter
const NewsletterSection: React.FC = () => {
  const [email, setEmail] = useState('');
  const [isSubscribed, setIsSubscribed] = useState(false);

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    setIsSubscribed(true);
    // TODO: Implémenter l'inscription à la newsletter
  };

  return (
    <section className="bg-gradient-to-r from-[#333697] to-[#FC9723] rounded-xl p-8 text-white">
      <div className="max-w-2xl mx-auto text-center">
        <Bell size={32} className="mx-auto mb-4" />
        <h2 className="text-2xl font-bold mb-4">
          Restez informé des dernières tendances entrepreneuriales
        </h2>
        <p className="mb-6">
          Recevez nos meilleurs articles, études de cas et ressources directement dans votre boîte mail.
        </p>
        {isSubscribed ? (
          <div className="bg-white/10 rounded-lg p-4">
            <p className="font-medium">Merci de votre inscription ! 🎉</p>
            <p className="text-sm mt-2">Vous recevrez bientôt nos meilleures ressources.</p>
          </div>
        ) : (
          <form onSubmit={handleSubmit} className="flex gap-4 max-w-md mx-auto">
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Votre adresse email"
              className="flex-1 px-4 py-2 rounded-lg text-gray-800 focus:outline-none focus:ring-2 focus:ring-white"
              required
            />
            <Button
              type="submit"
              className="px-6 py-2 bg-white text-[#333697] rounded-lg font-medium hover:bg-gray-100 transition-colors"
            >
              S'inscrire
            </Button>
          </form>
        )}
      </div>
    </section>
  );
};

// Composant Filtres
const BlogFilter: React.FC<{
  categories: CategoryProps[];
  selectedCategory: string;
  onFilterChange: (category: string) => void;
}> = ({ categories, selectedCategory, onFilterChange }) => (
  <div className="bg-white rounded-xl shadow-lg p-6 mb-8">
    <div className="flex items-center gap-4 mb-6">
      <Filter size={20} className="text-[#333697]" />
      <h3 className="text-lg font-semibold">Filtrer par catégorie</h3>
    </div>
    <div className="flex flex-wrap gap-3">
      {categories.map((category) => (
        <button
          key={category.id}
          onClick={() => onFilterChange(category.id)}
          className={`flex items-center gap-2 px-4 py-2 rounded-full border transition-colors ${selectedCategory === category.id
            ? 'bg-[#333697] text-white border-[#333697]'
            : 'border-gray-200 text-gray-600 hover:border-[#333697] hover:text-[#333697]'
            }`}
        >
          <category.icon size={16} />
          {category.name}
          <span className="text-sm">({category.count})</span>
        </button>
      ))}
    </div>
  </div>
);

// Composant Formation
const CourseCard: React.FC<CourseProps> = ({
  id,
  title,
  description,
  duration,
  modules,
  image,
  instructor,
  price,
  level
}) => (
  <Card className="overflow-hidden hover:shadow-lg transition-shadow duration-300">
    <Link to={`/formations/${id}`}>
      <img src={image} alt={title} className="w-full h-48 object-cover" />
    </Link>
    <CardContent className="p-6">
      <span className={`inline-block px-3 py-1 rounded-full text-sm mb-4 ${level === 'Débutant' ? 'bg-green-100 text-green-700' :
        level === 'Intermédiaire' ? 'bg-yellow-100 text-yellow-700' :
          'bg-red-100 text-red-700'
        }`}>
        {level}
      </span>
      <Link to={`/formations/${id}`}>
        <h3 className="text-xl font-bold text-[#333697] mb-2 hover:text-[#FC9723] transition-colors">
          {title}
        </h3>
      </Link>
      <p className="text-gray-600 mb-4">{description}</p>
      <div className="flex items-center justify-between mb-4">
        <div className="flex items-center gap-2">
          <img
            src={instructor.avatar}
            alt={instructor.name}
            className="w-8 h-8 rounded-full"
          />
          <div>
            <p className="font-medium text-[#333697]">{instructor.name}</p>
            <p className="text-sm text-gray-600">{instructor.role}</p>
          </div>
        </div>
      </div>
      <div className="flex items-center justify-between border-t pt-4">
        <div className="flex items-center gap-4 text-sm text-gray-600">
          <span className="flex items-center gap-1">
            <Clock size={14} />
            {duration}
          </span>
          <span className="flex items-center gap-1">
            <BookOpen size={14} />
            {modules} modules
          </span>
        </div>
        <span className="font-bold text-[#333697]">{price}€</span>
      </div>
    </CardContent>
  </Card>
);

// Composant Principal
const BlogPage: React.FC = () => {
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedCategory, setSelectedCategory] = useState("");
  const [posts, setPosts] = useState<PostProps[]>([]);
  const [courses, setCourses] = useState<CourseProps[]>([]);

  const categories: CategoryProps[] = [
    { id: 'startup', name: 'Création Startup', icon: Rocket, count: 15 },
    { id: 'tech', name: 'Technologies', icon: Code, count: 12 },
    { id: 'financement', name: 'Financement', icon: ChartBar, count: 8 },
    { id: 'collaboration', name: 'Collaboration', icon: Users, count: 10 },
    { id: 'innovation', name: 'Innovation', icon: Brain, count: 7 },
    { id: 'strategy', name: 'Stratégie', icon: Target, count: 9 }
  ];

  const handleInteraction = (type: string) => {
    console.log(`Interaction de type ${type}`);
    // Implémenter la logique d'interaction
  };

  return (
    <div className="min-h-screen bg-gradient-to-br from-white to-orange-50">
      <div className="max-w-7xl mx-auto px-4 py-16">
        {/* Hero Section avec recherche */}
        <div className="text-center mb-16">
          <h1 className="text-4xl font-bold mb-6 bg-gradient-to-r from-[#333697] to-[#FC9723] inline-block text-transparent bg-clip-text">
            Blog Pleeso
          </h1>
          <p className="text-gray-600 text-lg max-w-2xl mx-auto mb-8">
            Découvrez nos derniers articles, conseils et études de cas pour réussir votre aventure entrepreneuriale
          </p>
        </div>

        <div className="max-w-2xl mx-auto relative mb-16">
          <input
            type="text"
            placeholder="Rechercher un article..."
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            className="w-full px-6 py-3 rounded-full border shadow-sm focus:outline-none focus:ring-2 focus:ring-[#333697] pl-12"
          />
          <Search className="absolute left-4 top-1/2 transform -translate-y-1/2 text-gray-400" size={20} />
        </div>

        {/* Tendances */}
        <section className="mb-12">
          <div className="flex items-center gap-2 mb-6">
            <TrendingUp size={24} className="text-[#FC9723]" />
            <h2 className="text-2xl font-bold text-[#333697]">Tendances</h2>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
            {posts.slice(0, 3).map((post) => (
              <Link
                key={post.id}
                to={`/blog/${post.id}`}
                className="group relative overflow-hidden rounded-xl shadow-md hover:shadow-xl transition-shadow"
              >
                <img
                  src={post.image}
                  alt={post.title}
                  className="w-full h-48 object-cover group-hover:scale-105 transition-transform duration-300"
                />
                <div className="absolute inset-0 bg-gradient-to-t from-black/70 to-transparent p-6 flex flex-col justify-end">
                  <span className="text-white/80 text-sm mb-2">{post.category}</span>
                  <h3 className="text-white font-bold text-lg">{post.title}</h3>
                </div>
              </Link>
            ))}
          </div>
        </section>

        {/* Filtres */}
        <BlogFilter
          categories={categories}
          selectedCategory={selectedCategory}
          onFilterChange={setSelectedCategory}
        />

        {/* Articles principaux */}
        <section className="mb-16">
          <h2 className="text-2xl font-bold text-[#333697] mb-8">Articles Récents</h2>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
            {posts.map((post) => (
              <BlogPost
                key={post.id}
                {...post}
                onInteraction={handleInteraction}
              />
            ))}
          </div>
          <div className="text-center mt-8">
            <Button
              variant="outline"
              className="hover:bg-[#333697] hover:text-white transition-colors"
            >
              Voir plus d'articles
            </Button>
          </div>
        </section>

        {/* Newsletter */}
        <NewsletterSection />

        {/* Formations */}
        <section className="mb-16">
          <div className="flex items-center justify-between mb-8">
            <div className="flex items-center gap-2">
              <BookOpen size={24} className="text-[#FC9723]" />
              <h2 className="text-2xl font-bold text-[#333697]">Formations Recommandées</h2>
            </div>
            <Link
              to="/formations"
              className="text-[#333697] hover:text-[#FC9723] transition-colors flex items-center gap-2"
            >
              Voir toutes les formations
              <ArrowRight size={18} />
            </Link>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
            {courses.map((course) => (
              <CourseCard key={course.id} {...course} />
            ))}
          </div>
        </section>

        {/* Événements à venir */}
        <section className="mb-16">
          <div className="flex items-center gap-2 mb-8">
            <Calendar size={24} className="text-[#FC9723]" />
            <h2 className="text-2xl font-bold text-[#333697]">Événements à venir</h2>
          </div>
          <div className="bg-white rounded-xl shadow-lg p-6">
            <div className="divide-y">
              {[1, 2, 3].map((_, index) => (
                <div key={index} className="py-4 first:pt-0 last:pb-0">
                  <div className="flex items-start gap-4">
                    <div className="bg-gray-100 rounded-lg p-3 text-center min-w-[80px]">
                      <span className="text-[#333697] font-bold text-xl">{15 + index}</span>
                      <span className="text-gray-600 text-sm block">Nov</span>
                    </div>
                    <div>
                      <h3 className="font-semibold text-[#333697] mb-1">
                        Webinaire: Comment pitcher efficacement devant des investisseurs
                      </h3>
                      <p className="text-gray-600 text-sm mb-2">
                        Apprenez les techniques essentielles pour convaincre vos futurs investisseurs.
                      </p>
                      <div className="flex items-center gap-4 text-sm">
                        <span className="text-[#FC9723]">14:00 - 15:30</span>
                        <span className="text-gray-600">En ligne</span>
                        <span className="text-gray-600">Gratuit</span>
                      </div>
                    </div>
                    <Button variant="outline" className="ml-auto">
                      S'inscrire
                    </Button>
                  </div>
                </div>
              ))}
            </div>
            <div className="text-center mt-6">
              <Link
                to="/evenements"
                className="text-[#333697] hover:text-[#FC9723] transition-colors inline-flex items-center gap-2"
              >
                Voir tous les événements
                <ArrowRight size={18} />
              </Link>
            </div>
          </div>
        </section>

        {/* Footer avec tags */}
        <footer className="border-t border-gray-200 pt-8">
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            <div>
              <h3 className="text-lg font-semibold text-[#333697] mb-4">Tags populaires</h3>
              <div className="flex flex-wrap gap-2">
                {['StartupLife', 'Innovation', 'TechStartup', 'Entrepreneuriat', 'Collaboration'].map((tag) => (
                  <Link
                    key={tag}
                    to={`/tag/${tag}`}
                    className="px-3 py-1 bg-gray-100 rounded-full text-sm text-gray-600 hover:bg-[#333697] hover:text-white transition-colors"
                  >
                    #{tag}
                  </Link>
                ))}
              </div>
            </div>
            <div>
              <h3 className="text-lg font-semibold text-[#333697] mb-4">Catégories populaires</h3>
              <div className="space-y-2">
                {categories.slice(0, 5).map((category) => (
                  <Link
                    key={category.id}
                    to={`/categorie/${category.id}`}
                    className="flex items-center gap-2 text-gray-600 hover:text-[#333697] transition-colors"
                  >
                    <category.icon size={16} />
                    {category.name}
                  </Link>
                ))}
              </div>
            </div>
            <div>
              <h3 className="text-lg font-semibold text-[#333697] mb-4">Ressources utiles</h3>
              <div className="space-y-2">
                {[
                  { icon: Book, text: 'Guide du Pitch Perfect' },
                  { icon: FileText, text: 'Template Business Plan' },
                  { icon: ChartBar, text: 'KPIs Essentiels' },
                  { icon: Users, text: 'Guide de la Collaboration' },
                ].map((item, index) => (
                  <Link
                    key={index}
                    to="/ressources"
                    className="flex items-center gap-2 text-gray-600 hover:text-[#333697] transition-colors"
                  >
                    <item.icon size={16} />
                    {item.text}
                  </Link>
                ))}
              </div>
            </div>
          </div>
        </footer>
      </div>
    </div>
  );
};

export default BlogPage;
