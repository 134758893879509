import { AxiosResponse } from "axios";
import { PublicApi } from "./PublicApi";
import { SignupDto } from "../../../dto/Signup";
import { ConfirmDto } from "../../../dto/Confirm";

class AuthenticationApi extends PublicApi {

    public async login(email: string, password: string) {

        const data = { email, password };
        const response: AxiosResponse = await this.post(`/auth/login`, data);

        return response.data;
    }

    public async signup(signup: SignupDto) {
        const response: AxiosResponse = await this.post(`/auth/signup`, signup);
    }

    public async confirm(confirm: ConfirmDto) {
        const response: AxiosResponse = await this.post(`/auth/confirm`, confirm);
    }
}

export const authenticationApi = new AuthenticationApi();