import { Form, LoaderFunctionArgs, redirect, useActionData, useLocation, useNavigation } from "react-router-dom";
import { authProvider } from "../../service/AuthProvider";
import { GoogleLogin, GoogleOAuthProvider } from "@react-oauth/google";
import { jwtDecode } from "jwt-decode";

export async function loginAction({ request }: LoaderFunctionArgs) {
    let formData = await request.formData();
    let email = formData.get("email") as string | null;
    let password = formData.get("password") as string | null;

    // Validate our form inputs and return validation errors via useActionData()
    if (!email || !password) {
        return {
            error: "You must provide a email & password to log in",
        };
    }

    // Sign in and redirect to the proper destination if successful.
    try {
        await authProvider.signin(email, password);
    } catch (error) {
        // Unused as of now but this is how you would handle invalid
        // username/password combinations - just like validating the inputs
        // above
        return {
            error: "Invalid login attempt",
        };
    }

    let redirectTo = formData.get("redirectTo") as string | null;
    return redirect(redirectTo || "/");
}

export async function loginLoader() {
    if (authProvider.getTokenDetails()) {
        return redirect("/");
    }
    return null;
}

export function LoginPage() {
    let location = useLocation();
    let params = new URLSearchParams(location.search);
    let from = params.get("from") || "/";

    let navigation = useNavigation();
    let isLoggingIn = navigation.formData?.get("email") != null;

    let actionData = useActionData() as { error: string } | undefined;

    const handleSuccess = (credentialResponse: any) => {
        console.log(credentialResponse);
        const decoded = jwtDecode(credentialResponse.credential);

        console.log(decoded);
    };

    const handleError = () => {
        console.log('Login Failed');
    };

    return (
        <div>

            <Form method="post" replace>
                <input type="hidden" name="redirectTo" value={from} />
                <label>
                    email: <input name="email" />
                </label>{" "}
                <label>
                    password: <input name="password" />
                </label>{" "}
                <button type="submit" disabled={isLoggingIn}>
                    {isLoggingIn ? "Logging in..." : "Login"}
                </button>
                {actionData && actionData.error ? (
                    <p style={{ color: "red" }}>{actionData.error}</p>
                ) : null}
            </Form>
            <GoogleOAuthProvider clientId="396681253509-jj3uh40df40q0qo5sjmfdm567rigfcmu.apps.googleusercontent.com">
                <GoogleLogin
                    onSuccess={handleSuccess}
                    onError={handleError}
                />
            </GoogleOAuthProvider>
        </div>
    );
}