// import React from 'react';
import React, { useState } from 'react';
import { Routes, Route, Navigate, useNavigate } from 'react-router-dom';
import { Calendar, Globe, ChevronUp, ChevronDown } from 'lucide-react';
import { useOnboarding, OnboardingData, validateEmail, validatePassword, usePasswordStrength } from './OnboardingContext';
import { userService } from '../../service/UserService';


type StepProps<T extends keyof OnboardingData> = {
  onNext: (data: { [K in T]: OnboardingData[T] }) => void;
  onPrevious?: () => void;
};

// Common Components
const StepIndicator: React.FC<{ currentStep: number; totalSteps?: number }> = ({
  currentStep,
  totalSteps = 7
}) => {
  const getStepTitle = (step: number): string => {
    switch (step) {
      case 1:
        return "Type de profil";
      case 2:
        return "Porteur de projet";
      case 3:
        return "Votre métier";
      case 4:
        return "Vos informations personnelles";
      case 5:
        return "Votre appétence pour la technologie";
      case 6:
        return "Votre domaine d'activité";
      case 7:
        return "Vos identifiants de connexion";
      default:
        return "";
    }
  };

  return (
    <div className="w-full mb-8">
      <div className="text-left mb-4">
        <h2 className="text-xl font-semibold text-[#333697]">
          Etape {currentStep} sur {totalSteps}: {getStepTitle(currentStep)}
        </h2>
      </div>
      <div className="flex space-x-1">
        {[...Array(totalSteps)].map((_, index) => (
          <div
            key={index}
            className={`h-1 flex-1 rounded-full ${index === currentStep - 1 ? 'bg-pleeso-orange' : 'bg-gray-200'
              }`}
          />
        ))}
      </div>
    </div>
  );
};

interface NavigationButtonsProps {
  onPrevious?: () => void;
  onNext: () => void;
  isPreviousDisabled?: boolean;
  isNextDisabled?: boolean;
  nextLabel?: string;
  isLoading?: boolean;
}

const NavigationButtons: React.FC<NavigationButtonsProps> = ({
  onPrevious,
  onNext,
  isPreviousDisabled = false,
  isNextDisabled = false,
  nextLabel = "Suivant",
  isLoading = false
}) => {
  // Si nous n'avons que le bouton suivant, nous utilisons un layout différent
  const isSingleButton = isPreviousDisabled || !onPrevious;

  return (
    <div className={`flex ${isSingleButton ? 'justify-end' : 'justify-between'} mt-8`}>
      {!isPreviousDisabled && onPrevious && (
        <button
          onClick={(e) => {
            e.preventDefault();
            onPrevious();
          }}
          type="button"
          className="px-8 py-3 rounded-full text-[#333697] border-2 border-[#333697] font-medium 
            hover:bg-pleeso-orange hover:text-white hover:border-pleeso-orange transition-all duration-300"
        >
          Précédent
        </button>
      )}
      <button
        onClick={(e) => {
          e.preventDefault();
          onNext();
        }}
        type="button"
        disabled={isNextDisabled || isLoading}
        className={`px-8 py-3 rounded-full text-white font-medium transition-all duration-300 inline-block
          ${isNextDisabled || isLoading
            ? 'bg-gray-300 cursor-not-allowed'
            : nextLabel === 'Finaliser'
              ? 'bg-[#32308E] hover:bg-pleeso-orange'
              : 'bg-[#333697] hover:bg-pleeso-orange'
          }`}
      >
        {isLoading ?
          <div className="w-6 h-6 border-2 border-white border-t-transparent rounded-full animate-spin" /> :
          <div>{nextLabel}</div>
        }
      </button>
    </div>
  );
};


// Step Components
const ProfileTypeStep: React.FC<StepProps<'profileType'>> = ({ onNext }) => {
  const [selectedType, setSelectedType] = useState('');

  const profileTypes = [
    'Salarié',
    'Chef d\'entreprise',
    'Investisseur',
    'Etudiant',
    'Autres'
  ];

  const handleNext = () => {
    if (selectedType) {
      onNext({ profileType: selectedType });
    }
  };

  return (
    <div className="w-full">
      <StepIndicator currentStep={1} />
      <div className="mb-12">
        <h1 className="text-3xl font-bold text-gray-900 mb-4">
          Quel type de profil vous défini le mieux ?
        </h1>
        <p className="text-lg text-gray-600">
          Cela nous permettra de vous mettre en relation avec des profils compatibles
        </p>
      </div>

      <div className="space-y-4">
        {profileTypes.map((type) => (
          <button
            key={type}
            onClick={() => setSelectedType(type)}
            className={`w-full py-4 px-6 text-left text-lg font-medium rounded-lg border-2 transition-all duration-300
              ${selectedType === type
                ? 'border-[#333697] bg-blue-50 text-pleeso-blue'
                : 'border-gray-200 text-gray-700 hover:border-[#333697] hover:bg-blue-50'
              }`}
          >
            {type}
          </button>
        ))}
      </div>

      <NavigationButtons
        onNext={handleNext}
        isPreviousDisabled={true}
        isNextDisabled={!selectedType}
      />
    </div>
  );
};

const ProjectQuestionStep: React.FC<StepProps<'hasProject'>> = ({ onNext, onPrevious }) => {
  const [selectedAnswer, setSelectedAnswer] = useState<boolean | null>(null);

  const handleNext = () => {
    if (selectedAnswer !== null) {
      onNext({ hasProject: selectedAnswer });
    }
  };

  return (
    <div className="w-full">
      <StepIndicator currentStep={2} />

      <div className="mb-12">
        <h1 className="text-3xl font-bold text-gray-900 mb-4">
          Avez vous une idée de projet ?
        </h1>
        <p className="text-lg text-gray-600">
          Avez vous une idée de projet ou souhaitez vous rejoindre et accompagner un projet ?
        </p>
      </div>

      <div className="grid grid-cols-1 gap-8">
        <button
          onClick={() => setSelectedAnswer(true)}
          className={`relative rounded-lg border-2 transition-all duration-300 overflow-hidden
            ${selectedAnswer === true
              ? 'border-[#333697] bg-blue-50 text-pleeso-blue'
              : 'border-gray-200 text-gray-700 hover:border-[#333697] hover:bg-blue-50'
            }`}
        >
          <div className="p-1 h-full flex flex-col">
            <div className="h-16 flex items-center justify-center">
              <span className="text-2xl font-bold text-[#333697]">
                Oui
              </span>
            </div>
          </div>
        </button>

        <button
          onClick={() => setSelectedAnswer(false)}
          className={`relative rounded-lg border-2 transition-all duration-300 overflow-hidden
            ${selectedAnswer === false
              ? 'border-[#333697] bg-blue-50 text-pleeso-blue'
              : 'border-gray-200 text-gray-700 hover:border-[#333697] hover:bg-blue-50'
            }`}
        >
          <div className="p-1 h-full flex flex-col">
            <div className="h-16 flex items-center justify-center">
              <span className="text-2xl font-bold text-[#333697]">
                Non
              </span>
            </div>
          </div>
        </button>
      </div>

      <NavigationButtons
        onPrevious={onPrevious}
        onNext={handleNext}
        isNextDisabled={selectedAnswer === null}
      />
    </div>
  );
};

const ProfessionStep: React.FC<StepProps<'profession'>> = ({ onNext, onPrevious }) => {
  const [profession, setProfession] = useState({
    title: '',
    experience: 0
  });

  const [errors, setErrors] = useState({
    title: '',
    experience: ''
  });

  const validateForm = () => {
    const newErrors = {
      title: '',
      experience: ''
    };
    let isValid = true;

    if (!profession.title.trim()) {
      newErrors.title = 'Le métier est requis';
      isValid = false;
    }

    if (profession.experience < 0) {
      newErrors.experience = 'L\'expérience ne peut pas être négative';
      isValid = false;
    }

    setErrors(newErrors);
    return isValid;
  };

  const handleNext = () => {
    if (validateForm()) {
      onNext({ profession });
    }
  };

  const incrementExperience = () => {
    setProfession(prev => ({
      ...prev,
      experience: Math.min(prev.experience + 1, 50)
    }));
  };

  const decrementExperience = () => {
    setProfession(prev => ({
      ...prev,
      experience: Math.max(prev.experience - 1, 0)
    }));
  };

  // Vérifie si le formulaire est invalide
  const isFormInvalid = (): boolean => {
    return !profession.title.trim() || !!errors.title || !!errors.experience;
  };

  return (
    <div className="w-full">
      <StepIndicator currentStep={3} />

      <div className="mb-12">
        <h1 className="text-3xl font-bold text-gray-900 mb-4">
          Quel métier vous défini le mieux ?
        </h1>
      </div>

      <div className="space-y-8">
        <div className="border-2 border-[#333697] rounded-lg p-6">
          <label className="block text-[#333697] text-lg font-semibold mb-1">
            Métier principal* :
          </label>
          <p className="text-gray-600 mb-4">
            (Analyste financier, Data Analyst, Expert comptable,...)
          </p>
          <input
            type="text"
            value={profession.title}
            onChange={(e) => setProfession(prev => ({ ...prev, title: e.target.value }))}
            className={`w-full p-4 text-lg border-2 rounded-lg focus:ring-2 focus:ring-pleeso-orange outline-none
              ${errors.title ? 'border-red-500' : 'border-pleeso-blue'}`}
          />
          {errors.title && (
            <p className="mt-2 text-red-500 text-sm">{errors.title}</p>
          )}
        </div>

        <div className="border-2 border-[#333697] rounded-lg p-6">
          <label className="block text-[#333697] text-lg font-semibold mb-1">
            Nombre d'année d'expérience:
          </label>
          <p className="text-gray-600 mb-4">
            Depuis combien de temps évoluez-vous sur ce poste?
          </p>
          <div className="relative">
            <input
              type="number"
              value={profession.experience}
              onChange={(e) => setProfession(prev => ({
                ...prev,
                experience: parseInt(e.target.value) || 0
              }))}
              className={`w-full p-4 text-lg border-2 rounded-lg pr-12 focus:ring-2 focus:ring-pleeso-orange outline-none
                ${errors.experience ? 'border-red-500' : 'border-[#333697]'}`}
            />
            <div className="absolute right-2 top-1/2 -translate-y-1/2 flex flex-col">
              <button
                onClick={incrementExperience}
                className="text-[#333697] hover:text-pleeso-orange p-1"
              >
                <ChevronUp size={20} />
              </button>
              <button
                onClick={decrementExperience}
                className="text-[#333697] hover:text-pleeso-orange p-1"
              >
                <ChevronDown size={20} />
              </button>
            </div>
          </div>
          {errors.experience && (
            <p className="mt-2 text-red-500 text-sm">{errors.experience}</p>
          )}
        </div>
      </div>

      <NavigationButtons
        onPrevious={onPrevious}
        onNext={handleNext}
        isNextDisabled={isFormInvalid()}
      />
    </div>
  );
};

// Suite du fichier OnboardingFlow.tsx...

const PersonalInfoStep: React.FC<StepProps<'personalInfo'>> = ({ onNext, onPrevious }) => {
  const [personalInfo, setPersonalInfo] = useState({
    firstName: '',
    lastName: '',
    birthDate: '',
    location: ''
  });

  const [errors, setErrors] = useState({
    firstName: '',
    lastName: '',
    birthDate: '',
    location: ''
  });

  const validateForm = () => {
    const newErrors = {
      firstName: '',
      lastName: '',
      birthDate: '',
      location: ''
    };
    let isValid = true;

    if (!personalInfo.firstName.trim()) {
      newErrors.firstName = 'Le prénom est requis';
      isValid = false;
    }
    if (!personalInfo.lastName.trim()) {
      newErrors.lastName = 'Le nom est requis';
      isValid = false;
    }
    if (!personalInfo.birthDate) {
      newErrors.birthDate = 'La date de naissance est requise';
      isValid = false;
    }
    if (!personalInfo.location.trim()) {
      newErrors.location = 'La localisation est requise';
      isValid = false;
    }

    setErrors(newErrors);
    return isValid;
  };

  const handleNext = () => {
    if (validateForm()) {
      onNext({ personalInfo });
    }
  };

  return (
    <div className="w-full">
      <StepIndicator currentStep={4} />

      <div className="mb-12">
        <h1 className="text-3xl font-bold text-gray-900 mb-2 flex items-center">
          Faisons connaissance
          <span role="img" aria-label="smiling face" className="ml-2 text-2xl">😊</span>
        </h1>
        <p className="text-lg text-gray-600">
          Cela nous permettra à mieux vous identifier
        </p>
      </div>

      <div className="space-y-6">
        {/* Nom */}
        <div className="border-2 border-[#333697] rounded-lg p-6">
          <label className="block text-[#333697] text-lg font-medium mb-3">
            Nom
          </label>
          <input
            type="text"
            value={personalInfo.lastName}
            onChange={(e) => setPersonalInfo(prev => ({
              ...prev,
              lastName: e.target.value
            }))}
            placeholder="DOE"
            className={`w-full p-4 text-lg border-2 rounded-lg focus:ring-2 focus:ring-pleeso-orange outline-none
              ${errors.lastName ? 'border-red-500' : 'border-[#333697]'}`}
          />
          {errors.lastName && (
            <p className="mt-2 text-red-500 text-sm">{errors.lastName}</p>
          )}
        </div>

        {/* Prénom */}
        <div className="border-2 border-[#333697] rounded-lg p-6">
          <label className="block text-[#333697] text-lg font-medium mb-3">
            Prénom(s)
          </label>
          <input
            type="text"
            value={personalInfo.firstName}
            onChange={(e) => setPersonalInfo(prev => ({
              ...prev,
              firstName: e.target.value
            }))}
            placeholder="John"
            className={`w-full p-4 text-lg border-2 rounded-lg focus:ring-2 focus:ring-pleeso-orange outline-none
              ${errors.firstName ? 'border-red-500' : 'border-[#333697]'}`}
          />
          {errors.firstName && (
            <p className="mt-2 text-red-500 text-sm">{errors.firstName}</p>
          )}
        </div>

        {/* Date de naissance */}
        <div className="border-2 border-[#333697] rounded-lg p-6">
          <label className="block text-[#333697] text-lg font-medium mb-3">
            Date de naissance
          </label>
          <div className="relative">
            <input
              type="date"
              value={personalInfo.birthDate}
              onChange={(e) => setPersonalInfo(prev => ({
                ...prev,
                birthDate: e.target.value
              }))}
              className={`w-full p-4 text-lg border-2 rounded-lg pr-12 focus:ring-2 focus:ring-pleeso-orange outline-none
                ${errors.birthDate ? 'border-red-500' : 'border-[#333697]'}`}
            />
            <Calendar className="absolute right-4 top-1/2 -translate-y-1/2 w-6 h-6 text-[#333697]" />
          </div>
          {errors.birthDate && (
            <p className="mt-2 text-red-500 text-sm">{errors.birthDate}</p>
          )}
        </div>

        {/* Localisation */}
        <div className="border-2 border-[#333697] rounded-lg p-6">
          <label className="block text-[#333697] text-lg font-medium mb-3">
            Localisation (Ville)
          </label>
          <div className="relative">
            <input
              type="text"
              value={personalInfo.location}
              onChange={(e) => setPersonalInfo(prev => ({
                ...prev,
                location: e.target.value
              }))}
              placeholder="Laval (FRANCE)"
              className={`w-full p-4 text-lg border-2 rounded-lg pr-12 focus:ring-2 focus:ring-pleeso-orange outline-none
                ${errors.location ? 'border-red-500' : 'border-[#333697]'}`}
            />
            <Globe className="absolute right-4 top-1/2 -translate-y-1/2 w-6 h-6 text-[#333697]" />
          </div>
          {errors.location && (
            <p className="mt-2 text-red-500 text-sm">{errors.location}</p>
          )}
        </div>
      </div>

      <NavigationButtons
        onPrevious={onPrevious}
        onNext={handleNext}
        isNextDisabled={!personalInfo.firstName || !personalInfo.lastName || !personalInfo.birthDate || !personalInfo.location}
      />
    </div>
  );
};

const TechnicalAppetiteStep: React.FC<StepProps<'isTechnical'>> = ({ onNext, onPrevious }) => {
  const [isTechnical, setIsTechnical] = useState<boolean | null>(null);
  const handleNext = () => {
    if (isTechnical !== null) {
      onNext({ isTechnical });
    }
  };

  return (
    <div className="w-full">
      <StepIndicator currentStep={5} />

      <div className="mb-12">
        <h1 className="text-3xl font-bold text-gray-900 mb-16">
          Définirez vous votre profil comme technique ?
        </h1>
      </div>

      <div className="grid grid-cols-2 gap-8">
        <button
          onClick={() => {
            setIsTechnical(true);
          }}
          className={`relative rounded-lg border-2 transition-all duration-300 overflow-hidden
            ${isTechnical === true
              ? 'border-[#333697] bg-blue-50 text-pleeso-blue'
              : 'border-gray-200 text-gray-700 hover:border-[#333697] hover:bg-blue-50'
            }`}
        >
          <div className="p-8 h-full flex flex-col">
            <div className="h-16 flex items-center justify-center">
              <span className="text-4xl font-bold text-[#333697]">
                Oui
              </span>
            </div>
            <div className="mt-6">
              <p className="text-gray-600 text-center text-lg leading-relaxed">
                j'ai une appétence et je suis à l'aise avec les outils informatique et/ de développement.
              </p>
            </div>
          </div>
        </button>

        <button
          onClick={() => {
            setIsTechnical(false);
          }}
          className={`relative rounded-lg border-2 transition-all duration-300 overflow-hidden
            ${isTechnical === false
              ? 'border-[#333697] bg-blue-50 text-pleeso-blue'
              : 'border-gray-200 text-gray-700 hover:border-[#333697] hover:bg-blue-50'
            }`}
        >
          <div className="p-8 h-full flex flex-col">
            <div className="h-16 flex items-center justify-center">
              <span className="text-4xl font-bold text-[#333697]">
                Non
              </span>
            </div>
            <div className="mt-6">
              <p className="text-gray-600 text-center text-lg leading-relaxed">
                je préfère me concentrer sur la partie stratégique d'un projet.
              </p>
            </div>
          </div>
        </button>
      </div>

      <NavigationButtons
        onPrevious={onPrevious}
        onNext={handleNext}
        isNextDisabled={isTechnical === null}
      />
    </div>
  );
};

// Suite du fichier OnboardingFlow.tsx...

const ActivityDomainStep: React.FC<StepProps<'activityDomain'>> = ({ onNext, onPrevious }) => {
  const [selectedDomain, setSelectedDomain] = useState('');

  const domains = [
    'Technologie',
    'Finance',
    'Entrepreneuriat',
    'Management d\'entreprise',
    'Autres'
  ];

  return (
    <div className="w-full">
      <StepIndicator currentStep={6} />

      <div className="mb-12">
        <h1 className="text-3xl font-bold text-gray-900 mb-4">
          Dans quel domaine d'activité évoluez-vous ?
        </h1>
        <p className="text-lg text-gray-600 mb-8">
          Cela permettra d'affiner de faire correspondre les profils adéquats
        </p>
      </div>

      <div className="space-y-4 mb-12">
        {domains.map((domain) => (
          <button
            key={domain}
            onClick={() => setSelectedDomain(domain)}
            className={`w-full py-4 px-6 text-left text-xl rounded-lg border-2 transition-all duration-300
            ${selectedDomain === domain
                ? 'border-[#333697] bg-blue-50 text-pleeso-blue'
                : 'border-gray-200 text-gray-700 hover:border-[#333697] hover:bg-blue-50'
              }`}
          >
            {domain}
          </button>
        ))}
      </div>

      <NavigationButtons
        onPrevious={onPrevious}
        onNext={() => onNext({ activityDomain: selectedDomain })}
        isNextDisabled={!selectedDomain}
      />
    </div>
  );
};

const CredentialsStep: React.FC<StepProps<'credentials'>> = ({ onNext, onPrevious }) => {
  const [loading, setLoading] = useState(false);
  const [credentials, setCredentials] = useState({
    email: '',
    password: '',
    confirmPassword: ''
  });

  const [errors, setErrors] = useState({
    email: '',
    password: '',
    confirmPassword: ''
  });

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    setCredentials({
      ...credentials,
      [name]: value,
    });
    setErrors({ ...errors, [name]: '' })
  };

  const { strength, feedback } = usePasswordStrength(credentials.password);

  const validateForm = () => {
    const newErrors = {
      email: '',
      password: '',
      confirmPassword: ''
    };
    let isValid = true;

    // Email validation
    if (!credentials.email) {
      newErrors.email = 'L\'email est requis';
      isValid = false;
    } else if (!validateEmail(credentials.email)) {
      newErrors.email = 'Format d\'email invalide';
      isValid = false;
    }

    // Password validation
    const passwordValidation = validatePassword(credentials.password);
    if (!passwordValidation.isValid) {
      newErrors.password = passwordValidation.errors[0];
      isValid = false;
    }

    // Confirm password validation
    if (credentials.password !== credentials.confirmPassword) {
      newErrors.confirmPassword = 'Les mots de passe ne correspondent pas';
      isValid = false;
    }

    setErrors(newErrors);
    return isValid;
  };

  const handleNext = async () => {
    if (validateForm()) {
      const { confirmPassword, ...credentialsData } = credentials;
      setLoading(true);
      onNext({ credentials: credentialsData });
    }
  };

  const isFormInvalid = (): boolean => {
    return !credentials.email || !credentials.password || !credentials.confirmPassword || !!errors.email || !!errors.password || !!errors.confirmPassword;
  };

  return (
    <div className="w-full">
      <StepIndicator currentStep={7} />

      <div className="mb-12">
        <h1 className="text-3xl font-bold text-gray-900 mb-8">
          Veuillez saisir vos identifiants
        </h1>
      </div>

      <div className="space-y-6">
        {/* Email field */}
        <div className="border-2 border-[#333697] rounded-lg p-6">
          <label className="block text-[#333697] text-lg font-medium mb-3">
            Saisissez votre adresse mail
          </label>
          <input
            type="email"
            name="email"
            value={credentials.email}
            onChange={handleChange}
            placeholder="johnpololo@gmail.com"
            className={`w-full p-4 text-lg border-2 rounded-lg focus:ring-2 focus:ring-pleeso-orange outline-none
              ${errors.email ? 'border-red-500' : 'border-[#333697]'}`}
          />
          {errors.email && (
            <p className="mt-2 text-red-500 text-sm">{errors.email}</p>
          )}
        </div>

        {/* Password field */}
        <div className="border-2 border-[#333697] rounded-lg p-6">
          <label className="block text-[#333697] text-lg font-medium mb-3">
            Saisissez votre mot de passe
          </label>
          <div className="space-y-2">
            <input
              type="password"
              name="password"
              value={credentials.password}
              onChange={handleChange}
              className={`w-full p-4 text-lg border-2 rounded-lg focus:ring-2 focus:ring-pleeso-orange outline-none
                ${errors.password ? 'border-red-500' : 'border-[#333697]'}`}
            />
            {/* Password strength indicator */}
            <div className="mt-2">
              <div className="w-full bg-gray-200 rounded-full h-2">
                <div
                  className={`h-full rounded-full transition-all duration-300 ${strength < 40 ? 'bg-red-500' :
                    strength < 60 ? 'bg-yellow-500' :
                      strength < 80 ? 'bg-blue-500' : 'bg-green-500'
                    }`}
                  style={{ width: `${strength}%` }}
                />
              </div>
              {feedback.length > 0 && (
                <ul className="mt-2 text-sm text-gray-600">
                  {feedback.map((item, index) => (
                    <li key={index} className="flex items-center">
                      <span className="mr-2">•</span>
                      {item}
                    </li>
                  ))}
                </ul>
              )}
            </div>
          </div>
        </div>

        {/* Confirm Password field */}
        <div className="border-2 border-[#333697] rounded-lg p-6">
          <label className="block text-[#333697] text-lg font-medium mb-3">
            Confirmez votre mot de passe
          </label>
          <input
            type="password"
            name="confirmPassword"
            value={credentials.confirmPassword}
            onChange={handleChange}
            className={`w-full p-4 text-lg border-2 rounded-lg focus:ring-2 focus:ring-pleeso-orange outline-none
              ${errors.confirmPassword ? 'border-red-500' : 'border-[#333697]'}`}
          />
          {errors.confirmPassword && (
            <p className="mt-2 text-red-500 text-sm">{errors.confirmPassword}</p>
          )}
        </div>
      </div>

      <NavigationButtons
        onPrevious={onPrevious}
        onNext={handleNext}
        isNextDisabled={isFormInvalid()}
        nextLabel="Finaliser"
        isLoading={loading}
      />
    </div>
  );
};

const CreatedUser: React.FC = () => {
  return (
    <div className="w-full">
      Compte créé. Un e-mail d'activation a été envoyé à votre adresse e-mail.
    </div>
  );
};

// Main OnboardingFlow Component
const OnboardingFlow: React.FC = () => {
  const navigate = useNavigate();
  const { updateData, data, setCurrentStep } = useOnboarding();

  const handleStepComplete = (
    step: number,
    data: Partial<OnboardingData>
  ) => {
    updateData(data);
    setCurrentStep(step + 1);
    navigate(`step${step + 1}`);
  };

  const handleFinalStep = async (pdata: Partial<OnboardingData>) => {
    try {
      // Mise à jour des données finales
      updateData(pdata);

      // Ici vous pouvez ajouter l'appel API pour sauvegarder les données
      // await saveOnboardingData(data);
      console.log({ ...data, ...pdata });
      await userService.createUser({ ...data, ...pdata });

      navigate('created')
    } catch (error) {
      console.error('Error during finalization:', error);
      // Gérer l'erreur (afficher un message, etc.)
    }
  };

  return (
    <div className="bg-white">
      <div className="max-w-2xl mx-auto py-12 px-4">
        {/* Routes */}
        <Routes>
          <Route
            path="step1"
            element={
              <ProfileTypeStep
                onNext={(data) => handleStepComplete(1, data)}
              />
            }
          />
          <Route
            path="step2"
            element={
              <ProjectQuestionStep
                onNext={(data) => handleStepComplete(2, data)}
                onPrevious={() => {
                  setCurrentStep(1);
                  navigate('step1');
                }}
              />
            }
          />
          <Route
            path="step3"
            element={
              <ProfessionStep
                onNext={(data) => handleStepComplete(3, data)}
                onPrevious={() => {
                  setCurrentStep(2);
                  navigate('step2');
                }}
              />
            }
          />
          <Route
            path="step4"
            element={
              <PersonalInfoStep
                onNext={(data) => handleStepComplete(4, data)}
                onPrevious={() => {
                  setCurrentStep(3);
                  navigate('step3');
                }}
              />
            }
          />
          <Route
            path="step5"
            element={
              <TechnicalAppetiteStep
                onNext={(data) => handleStepComplete(5, data)}
                onPrevious={() => {
                  setCurrentStep(4);
                  navigate('step4');
                }}
              />
            }
          />
          <Route
            path="step6"
            element={
              <ActivityDomainStep
                onNext={(data) => handleStepComplete(6, data)}
                onPrevious={() => {
                  setCurrentStep(5);
                  navigate('step5');
                }}
              />
            }
          />
          <Route
            path="step7"
            element={
              <CredentialsStep
                onNext={handleFinalStep}
                onPrevious={() => {
                  setCurrentStep(6);
                  navigate('step6');
                }}
              />
            }
          />
          <Route
            path="created"
            element={
              <CreatedUser />
            }
          />
          <Route
            path="*"
            element={<Navigate to="/signup/step1" replace />}
          />
        </Routes>

        {/* Debug Panel - uniquement en développement
        {process.env.NODE_ENV === 'development' && (
          <DebugPanel />
        )} */}
      </div>
    </div>
  );
};

export default OnboardingFlow;