import * as React from "react";
import { Slot } from "@radix-ui/react-slot";
import { cva, type VariantProps } from "class-variance-authority";

// Utilitaire pour fusionner les classes CSS
const cn = (...classes: Array<string | boolean | undefined>): string => {
  return classes.filter(Boolean).join(" ");
};

// Définition des variantes de bouton avec class-variance-authority
const buttonVariants = cva(
  // Styles de base communs à tous les boutons
  "inline-flex items-center justify-center rounded-lg text-sm font-medium transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-[#333697] disabled:pointer-events-none disabled:opacity-50",
  {
    variants: {
      variant: {
        default: "bg-[#333697] text-white hover:bg-[#333697]/90",
        destructive: "bg-red-500 text-white hover:bg-red-600",
        outline: "border border-[#333697] text-[#333697] bg-transparent hover:bg-[#333697] hover:text-white",
        secondary: "bg-gray-100 text-gray-900 hover:bg-gray-200",
        ghost: "text-[#333697] hover:bg-[#333697]/10",
        link: "text-[#333697] underline-offset-4 hover:underline",
        gradient: "bg-gradient-to-r from-[#333697] to-[#FC9723] text-white hover:opacity-90",
      },
      size: {
        default: "h-10 px-4 py-2",
        sm: "h-8 px-3 text-xs",
        lg: "h-12 px-8 text-base",
        icon: "h-10 w-10",
      },
      fullWidth: {
        true: "w-full",
        false: "w-auto",
      },
    },
    defaultVariants: {
      variant: "default",
      size: "default",
      fullWidth: false,
    },
  }
);

// Types pour les props du bouton
interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof buttonVariants> {
  asChild?: boolean;
  loading?: boolean;
  icon?: React.ReactNode;
  iconPosition?: "left" | "right";
}

// Composant Button
const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  ({
    className,
    variant,
    size,
    fullWidth,
    asChild = false,
    loading = false,
    icon,
    iconPosition = "left",
    children,
    disabled,
    ...props
  }, ref) => {
    const Comp = asChild ? Slot : "button";

    return (
      <Comp
        className={cn(
          buttonVariants({ variant, size, fullWidth, className })
        )}
        ref={ref}
        disabled={disabled || loading}
        {...props}
      >
        {loading && (
          <svg
            className="mr-2 h-4 w-4 animate-spin"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
          >
            <circle
              className="opacity-25"
              cx="12"
              cy="12"
              r="10"
              stroke="currentColor"
              strokeWidth="4"
            />
            <path
              className="opacity-75"
              fill="currentColor"
              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
            />
          </svg>
        )}
        {icon && iconPosition === "left" && !loading && (
          <span className="mr-2">{icon}</span>
        )}
        {children}
        {icon && iconPosition === "right" && !loading && (
          <span className="ml-2">{icon}</span>
        )}
      </Comp>
    );
  }
);
Button.displayName = "Button";

// Composant de démonstration
const ButtonDemo: React.FC = () => {
  return (
    <div className="flex flex-col gap-8 p-6">
      {/* Variantes de base */}
      <div className="flex flex-wrap gap-4">
        <Button>Default Button</Button>
        <Button variant="outline">Outline Button</Button>
        <Button variant="secondary">Secondary Button</Button>
        <Button variant="ghost">Ghost Button</Button>
        <Button variant="link">Link Button</Button>
        <Button variant="gradient">Gradient Button</Button>
        <Button variant="destructive">Destructive Button</Button>
      </div>

      {/* Tailles */}
      <div className="flex flex-wrap items-center gap-4">
        <Button size="sm">Small Button</Button>
        <Button size="default">Default Button</Button>
        <Button size="lg">Large Button</Button>
        <Button size="icon">🔍</Button>
      </div>

      {/* États */}
      <div className="flex flex-wrap gap-4">
        <Button loading>Loading Button</Button>
        <Button disabled>Disabled Button</Button>
        <Button fullWidth>Full Width Button</Button>
      </div>

      {/* Boutons avec icônes */}
      <div className="flex flex-wrap gap-4">
        <Button icon={<span>👋</span>}>Left Icon</Button>
        <Button icon={<span>➡️</span>} iconPosition="right">
          Right Icon
        </Button>
      </div>

      {/* Boutons avec gradient et effets */}
      <div className="flex flex-wrap gap-4">
        <Button 
          variant="gradient" 
          className="group transition-all duration-300"
        >
          <span className="group-hover:translate-x-1 transition-transform">
            Get Started
          </span>
        </Button>
        <Button 
          variant="outline"
          className="group hover:shadow-lg transition-all duration-300"
        >
          Learn More
        </Button>
      </div>
    </div>
  );
};

export { Button, buttonVariants, type ButtonProps, ButtonDemo };