import { TokenDetails } from "../dto/TokenDetails";
import { authenticationApi } from "./api/public/AuthenticationApi";
import Cookies from "universal-cookie";

class AuthProvider {
    cookies = new Cookies();

    async signin(email: string, password: string) {
        const result = await authenticationApi.login(email, password);
        const now = Date.now();
        this.cookies.set("pleeso", result, { path: "/", expires: new Date(now + 60 * 60 * 1000) });
    }

    async signout() {
        this.cookies.remove("pleeso");
    }

    getTokenDetails(): TokenDetails | undefined {
        return this.cookies.get("pleeso");
    }
}

export const authProvider = new AuthProvider();