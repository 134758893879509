import { useEffect, useState } from 'react';
import './Dashboard.css';
import { userApi } from '../../service/api/private/UserApi';
import { LoaderFunctionArgs, redirect, useRouteLoaderData } from 'react-router-dom';
import { TokenDetails } from '../../dto/TokenDetails';
import { authProvider } from '../../service/AuthProvider';

export function Dashboard() {
    const [data, setData] = useState();
    const { tokenDetails } = useRouteLoaderData("root") as { tokenDetails: TokenDetails | null | null };

    useEffect(() => {
        if (!data && tokenDetails?.userId) {
            userApi.getUser(tokenDetails.userId)
                .then((result) => setData(result))
                .catch((error) => console.error(error))
        }
    }, [data, tokenDetails]);

    return (
        <div>
            <p>
                {JSON.stringify(data)}
            </p>
        </div>
    );
}

export async function userLoader({ request }: LoaderFunctionArgs) {
    // If the user is not logged in and tries to access `/protected`, we redirect
    // them to `/login` with a `from` parameter that allows login to redirect back
    // to this page upon successful authentication
    if (!authProvider.getTokenDetails()) {
        let params = new URLSearchParams();
        params.set("from", new URL(request.url).pathname);
        return redirect("/login?" + params.toString());
    }
    return null;
}