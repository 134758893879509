import { AxiosResponse } from "axios";
import { PublicApi } from "./PublicApi";
import { UserDto } from "../../../dto/User";

class CreateUserApi extends PublicApi {

    public async createUserDb(user: UserDto) {
        const response: AxiosResponse = await super.post("/public/db/users", user);
        return response.data;
    }

    public async createUserEs(userId: string) {
        const response: AxiosResponse = await super.post(`/public/es/users/${userId}`, {});
        return response.data;
    }
}

export const createUserApi = new CreateUserApi();