import React, { useState, useEffect, useRef, ReactNode } from 'react';
import { motion, AnimatePresence, useScroll, useSpring } from 'framer-motion';
import { Link, LoaderFunctionArgs, redirect, useNavigate } from 'react-router-dom';
import trans from '../../assets/images/trans.png'
import Image_Hero_2 from '../../assets/images/Image_Hero_2.png';
import Matching from '../../assets/images/Matching.png';
import brainstorming from '../../assets/images/brainstorming.jpg';
import trendTech from '../../assets/images/trend_tech.jpeg';
import financing from '../../assets/images/financing.jpg';
import financingSuccess from '../../assets/images/financing_success.jpeg';
import chooseCofounder from '../../assets/images/choose_cofounder.png';
import resilience from '../../assets/images/resilience.jpeg';
import collaboration from '../../assets/images/collaboration.png';
import chat from '../../assets/images/chat.png';
import management from '../../assets/images/management.png';
import brain from '../../assets/images/brain.png';
import matching2 from '../../assets/images/matching2.png';
import Com_int from '../../assets/images/Com_int.png';
import Gestion_tache from '../../assets/images/Gestion_tache.png';
import IA_modele from '../../assets/images/IA_modele.jpg';
import Kab_image from '../../assets/images/Kab_image.png';
import Sophie from '../../assets/images/Sophie.jpg';
import Nicolas from '../../assets/images/Nicolas.jpg';
import Elearning from '../../assets/images/Elearning.png';
import Blog_image from '../../assets/images/Blog.png';
import Produit from '../../assets/images/Produit.png';
import Reseau from '../../assets/images/Reseau.png';
import deroulant from '../../assets/images/deroulant.png';
import { AccessButton } from '../../components/AccessButon';

////////////////////////////////////////////////// COMPOSANT BORDURE ECRAN /////////////////////

interface ContentWrapperProps {
    children: ReactNode;
    className?: string;
}

const ContentWrapper: React.FC<ContentWrapperProps> = ({ children, className = '' }) => {
    return (
        <div className={`max-w-[1800px] mx-auto px-12 md:px-[1cm] ${className}`}>
            {children}
        </div>
    );
};



///////////////////////////// HERO COMPONENT ///////////////////////////////////

const Hero: React.FC = () => (
    <section className="flex flex-col self-stretch px-20 mt-16 w-full max-md:px-5 max-md:mt-8 max-md:max-w-full">
        <div className="max-md:max-w-full">
            <div className="flex gap-8 items-center max-md:flex-col"> {/* Ajout de items-center */}
                {/* Colonne texte */}
                <div className="flex flex-col w-6/12 max-md:ml-0 max-md:w-full md:min-w-[400px]">
                    <div className="flex flex-col self-stretch mb-auto w-[90%] text-zinc-800 
              max-md:mt-8 max-md:w-full max-md:items-center 
              md:mt-0"> {/* Ajusté le mt-16 pour tablette */}
                        {/* Badge */}
                        <div className="inline-flex self-start px-4 py-2 mb-8 text-sm font-medium text-[#FC9723] bg-orange-50 border border-[#FC9723] rounded-full 
                max-md:self-center max-md:text-xs max-md:px-3 max-md:py-1.5 max-md:mb-6
                md:text-xs md:px-3 md:py-1.5 md:mb-6">
                            Plus de 10,000 inscrits à la version beta
                        </div>

                        {/* Titre + description */}
                        <div className="flex flex-col max-md:items-center">
                            <h1 className="text-5xl font-medium tracking-tighter 
                  max-md:max-w-full max-md:text-3xl max-md:text-center max-md:leading-tight
                  md:text-4xl md:leading-tight md:w-[95%]">
                                Commencez dès aujourd'hui à trouver le cofondateur qui vous convient parfaitement :{" "}
                                <span className="font-semibold text-[#FC9723]">matchez et construisez!</span>
                            </h1>
                            <p className="mt-6 text-xl 
                  max-md:max-w-full max-md:text-base max-md:text-center max-md:leading-relaxed max-md:px-4
                  md:text-lg md:leading-relaxed md:w-[95%]">
                                La première plateforme qui transforme votre idée en startup financée.
                                Connectez-vous aux meilleurs talents Business et Tech, trouvez votre cofondateur idéal et accédez directement à des investisseurs prêts à vous financer.
                            </p>
                        </div>

                        {/* Bouton */}
                        <div className="flex gap-2 items-start self-start mt-10 text-lg text-white max-md:self-center md:mt-8">
                            <Link
                                to="/waitlist"
                                className="gap-2 self-stretch px-1 py-3 bg-[#333697] border border-white border-solid min-h-[52px] min-w-[240px] rounded-[48px] 
                    max-md:px-6 max-md:py-3 max-md:text-base 
                    md:text-base md:min-w-[200px] md:py-2
                    hover:bg-[#e88b1f] transition-colors flex items-center justify-center"
                            >
                                Demande d'accès
                            </Link>
                        </div>
                    </div>
                </div>

                {/* Section image */}
                <div className="flex flex-col w-6/12 max-md:ml-0 max-md:w-full md:min-w-[400px] md:max-w-[600px] md:self-center"> {/* Ajout de md:self-center */}
                    <div className="relative w-full h-0 pb-[83.33%] rounded-2xl overflow-hidden shadow-lg">
                        <img
                            loading="lazy"
                            src={Image_Hero_2}
                            alt="Illustration of collaboration"
                            className="absolute inset-0 w-full h-full object-cover"
                        />
                    </div>
                </div>
            </div>
        </div>
        <img loading="lazy" src={trans} alt="" className="object-contain self-end mt-28 mr-80 max-w-full aspect-[4.07] w-[167px] max-md:mt-10 max-md:mr-2.5" />
    </section>
);



///////////////////////// FEATURES COMPONENT /////////////////////

// Types
interface TimerProps {
    duration: number;
    onComplete: () => void;
    isActive: boolean;
}

interface FeatureProps {
    title: string;
    description: string;
    image: string;
    isActive: boolean;
    onClick: () => void;
    onComplete: () => void;
}

interface FeatureData {
    title: string;
    description: string;
    image: string;
}

// Composant Timer
const Timer: React.FC<TimerProps> = ({ duration, onComplete, isActive }) => {
    const [progress, setProgress] = useState(0);

    useEffect(() => {
        if (!isActive) {
            setProgress(0);
            return;
        }

        const interval = setInterval(() => {
            setProgress((oldProgress) => {
                if (oldProgress >= 100) {
                    clearInterval(interval);
                    onComplete();
                    return 0;
                }
                return oldProgress + (100 / duration) * 0.1;
            });
        }, 100);

        return () => clearInterval(interval);
    }, [duration, onComplete, isActive]);

    return (
        <div className="w-full bg-[#B3B4E5] h-1 rounded-t-lg">
            <div
                className="bg-[#333697] h-1 rounded-tl-lg transition-all duration-100 ease-linear"
                style={{ width: `${progress}%` }}
            />
        </div>
    );
};

// Composant Feature
const Feature: React.FC<FeatureProps> = ({
    title,
    description,
    image,
    isActive,
    onClick,
    onComplete
}) => (
    <div
        className={`flex flex-col rounded-2xl overflow-hidden transition-all duration-500 ease-in-out cursor-pointer
          ${isActive ? 'bg-white shadow-lg' : 'bg-white bg-opacity-90'}`}
        onClick={onClick}
    >
        {/* Image mobile uniquement */}
        <div className="md:hidden w-full h-30">
            <img
                src={image}
                alt={title}
                className="w-full h-30 object-cover"
            />
        </div>

        <Timer duration={10} onComplete={onComplete} isActive={isActive} />
        <div className="p-2 md:p-4">
            <h3 className={`text-lg md:text-xl font-medium transition-colors duration-500 
            ${isActive ? 'text-[#333697]' : 'text-[#333697] opacity-80'}`}
            >
                {title}
            </h3>
            <div className={`mt-2 overflow-hidden transition-all duration-500 ease-in-out 
            ${isActive ? 'max-h-28 opacity-100' : 'max-h-0 opacity-0'}`}
            >
                <p className="text-sm leading-relaxed text-gray-700">{description}</p>
            </div>
        </div>
    </div>
);

const FeatureSection: React.FC = () => {
    const [activeFeature, setActiveFeature] = useState(0);

    const features: FeatureData[] = [
        {
            title: "Votre Cofondateur sur mesure",
            description: "Notre algorithme analyse plus de 50 critères de compatibilité pour vous matcher avec le partenaire qui complète parfaitement vos compétences. Trouvez en quelques jours celui ou celle qui partagera votre vision et multipliera vos chances de succès.",
            image: Matching
        },
        {
            title: "Une expertise premium à portée de main",
            description: "Bénéficiez instantanément d'une bibliothèque de ressources validées par des entrepreneurs à succès : templates, guides stratégiques, et masterclass exclusives. Évitez les erreurs classiques et accélérez votre développement.",
            image: brainstorming
        },
        {
            title: "Le financement devient accessible",
            description: "Présentez-vous devant notre réseau d'investisseurs avec une équipe solide et un projet structuré. Grâce à notre accompagnement personnalisé et nos connexions privilégiées, nous nous aidons à lever des fonds dès aujourd'hui !",
            image: financing
        }
    ];

    useEffect(() => {
        const timer = setTimeout(() => {
            setActiveFeature((prev) => (prev + 1) % features.length);
        }, 10000);

        return () => clearTimeout(timer);
    }, [activeFeature, features.length]);

    return (
        // Ajustement de la hauteur pour tablette
        <section className="w-full bg-[#B3B4E5] min-h-screen md:min-h-0 md:h-[500px] lg:h-[800px]">
            <div className="max-w-[1440px] mx-auto px-5 md:px-1 py-8 md:py-6 lg:py-16 md:px-[1cm]">
                <div className="grid grid-cols-1 md:grid-cols-2 md:gap-3">
                    {/* Colonne gauche - Ajustement des marges et espacements pour tablette */}
                    <div className="flex flex-col h-full md:max-w-[550px]">
                        <div className="mb-6 md:mb-4 lg:mb-8">
                            <h2 className="text-xl md:text-3xl lg:text-4xl font-medium text-[#333697] text-center md:text-left leading-tight">
                                <span>Une plateforme qui réunit </span>
                                <span className="font-semibold">tout le nécéssaire pour votre lancement d'activité</span>
                            </h2>
                        </div>

                        {/* Conteneur des features avec hauteur réduite sur tablette */}
                        <div className="flex-grow space-y-4 md:space-y-3 lg:space-y-4 md:h-[280px] lg:h-[360px] overflow-y-auto md:overflow-visible mt-10 md:mt-6 lg:mt-10">
                            {features.map((feature, index) => (
                                <Feature
                                    key={index}
                                    {...feature}
                                    isActive={index === activeFeature}
                                    onClick={() => setActiveFeature(index)}
                                    onComplete={() => setActiveFeature((prev) => (prev + 1) % features.length)}
                                />
                            ))}
                        </div>

                        {/* Bouton avec marges réduites sur tablette */}
                        <div className="mt-6 md:mt-4 lg:mt-8 flex justify-center md:justify-start">
                            <AccessButton
                                destination="waitlist"
                                className="px-6 py-3 md:px-6 md:py-3 lg:px-8 lg:py-4 bg-[#333697] rounded-[40px] 
                      text-base font-medium text-white 
                      hover:bg-[#e88b1f] transition-colors 
                      w-[200px] md:w-[220px] lg:w-[240px] text-center"
                            >
                                Rejoindre Pleeso
                            </AccessButton>
                        </div>
                    </div>

                    {/* Colonne droite - Ajustement de la hauteur de l'image pour tablette */}
                    <div className="hidden md:block relative rounded-2xl bg-white overflow-hidden md:h-[450px] lg:h-[550px] md:mt-10 lg:mt-20">
                        {features.map((feature, index) => (
                            <img
                                key={index}
                                src={feature.image}
                                alt={feature.title}
                                className={`absolute top-0 left-0 w-full h-full object-cover transition-opacity duration-1000 
                      ${index === activeFeature ? 'opacity-100' : 'opacity-0'}`}
                            />
                        ))}
                    </div>
                </div>
            </div>
        </section>
    );
};


/////////////////////////////////  PROCESS EXPLANATION /////////////////////////

const ProcessExplanation: React.FC = () => {
    const containerVariants = {
        hidden: { opacity: 0 },
        visible: {
            opacity: 1,
            transition: {
                duration: 0.6,
                staggerChildren: 0.3
            }
        }
    };

    const itemVariants = {
        hidden: { opacity: 0, y: 20 },
        visible: {
            opacity: 1,
            y: 0,
            transition: {
                duration: 0.6
            }
        }
    };

    return (
        <section className="w-full bg-gray-100">
            <motion.div
                className="max-w-[1440px] mx-auto px-5 lg:px-20 py-16 md:py-24"
                variants={containerVariants}
                initial="hidden"
                whileInView="visible"
                viewport={{ once: true }}
            >
                <motion.h2
                    className="text-3xl md:text-4xl font-bold text-center mb-10 text-[#333697]"
                    variants={itemVariants}
                >
                    Comment fonctionne Pleeso ?
                </motion.h2>

                <motion.div
                    className="w-full max-w-3xl mx-auto"
                    variants={itemVariants}
                >
                    <div className="relative w-full aspect-video rounded-lg overflow-hidden shadow-lg bg-gray-200">
                        <img
                            src="/api/placeholder/800/450"
                            alt="Video placeholder"
                            className="w-full h-full object-cover"
                        />
                        <div className="absolute inset-0 flex items-center justify-center">
                            <button
                                className="w-16 h-16 md:w-20 md:h-20 bg-white/90 rounded-full flex items-center justify-center shadow-lg hover:bg-white transition-colors duration-300"
                                aria-label="Play video"
                            >
                                <svg
                                    className="w-8 h-8 md:w-10 md:h-10 text-[#333697]"
                                    viewBox="0 0 24 24"
                                    fill="currentColor"
                                >
                                    <path d="M8 5v14l11-7z" />
                                </svg>
                            </button>
                        </div>
                    </div>

                    <motion.div
                        className="mt-8 text-center text-gray-600 px-4"
                        variants={itemVariants}
                    >
                        <p className="text-lg md:text-xl">
                            Découvrez en vidéo comment Pleeso peut vous aider à trouver le cofondateur idéal
                            et lancer votre startup avec succès.
                        </p>
                    </motion.div>
                </motion.div>
            </motion.div>
        </section>
    );
};

/////////////////////////////// CALL TO ACTION COMPONENT //////////////

const CallToAction: React.FC = () => (
    <section className="w-3/4 mx-auto px-5 py-5 mt-16 mb-8 text-2xl text-center bg-orange-400 text-zinc-800 rounded-xl
        max-md:w-[85%] max-md:px-4 max-md:py-4 max-md:mt-8 max-md:mb-2 max-md:text-lg">
        Vous trouverez tout ceci sur Pleeso et{" "}
        <span className="font-semibold">nul par ailleurs</span> !
    </section>
);

//////////////////////////////////// FEATURE LIST COMPONENT //////////////////////////

const ZigzagLine: React.FC<{ isReversed: boolean }> = ({ isReversed }) => {
    const ref = useRef(null);
    const { scrollYProgress } = useScroll({
        target: ref,
        offset: ["start end", "end start"]
    });

    const pathLength = useSpring(scrollYProgress, {
        stiffness: 100,
        damping: 30,
        restDelta: 0.001
    });

    const zigzagPath = isReversed
        ? "M5 0 Q 45 40 5 80 Q 45 120 5 160"
        : "M45 0 Q 5 40 45 80 Q 5 120 45 160";

    return (
        <motion.div
            ref={ref}
            className="absolute left-1/2 top-0 bottom-0 w-px"
            style={{ height: "100%" }}
        >
            <svg width="50" height="160" viewBox="0 0 50 160" preserveAspectRatio="none">
                <motion.path
                    d={zigzagPath}
                    fill="none"
                    strokeWidth="2"
                    stroke="rgb(59, 130, 246)"
                    strokeLinecap="round"
                    initial={{ pathLength: 0 }}
                    style={{ pathLength }}
                />
            </svg>
        </motion.div>
    );
};

const FeatureDetail: React.FC<{ icon: string; title: string; description: string; image: string; isReversed: boolean }> = ({ icon, title, description, image, isReversed }) => (
    <div className={`relative flex gap-8 justify-between items-center mt-12 w-full max-md:flex-col max-md:mt-8 max-md:max-w-full ${isReversed ? 'flex-row-reverse' : ''}`}>
        <div className="absolute left-1/2 transform -translate-x-1/2 h-full w-1 z-0">
            <ZigzagLine isReversed={isReversed} />
        </div>
        <motion.div
            className="flex flex-col self-stretch my-auto w-[calc(50%-2rem)] z-10 max-md:w-full"
            initial={{ opacity: 0, y: 30 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.4 }}
            viewport={{ once: true }}
        >
            <motion.div
                className="flex flex-col p-6 bg-white rounded-lg shadow-lg"
                whileHover={{ scale: 1.03 }}
                transition={{ type: "spring", stiffness: 300 }}
            >
                <motion.div
                    className="flex gap-2 items-center mb-3"
                    initial={{ x: -15, opacity: 0 }}
                    animate={{ x: 0, opacity: 1 }}
                    transition={{ delay: 0.2 }}
                >
                    <img loading="lazy" src={icon} alt="" className="object-contain shrink-0 w-6 aspect-square" />
                    <h3 className="text-xl font-medium text-zinc-800">{title}</h3>
                </motion.div>
                <motion.p
                    className="text-base text-zinc-800"
                    initial={{ y: 15, opacity: 0 }}
                    animate={{ y: 0, opacity: 1 }}
                    transition={{ delay: 0.3 }}
                >
                    {description}
                </motion.p>
            </motion.div>
        </motion.div>
        <motion.div
            className="w-[calc(45%-2rem)] aspect-[1.25] z-10 overflow-hidden rounded-xl max-md:w-full bg-gray-100 p-4 flex items-center justify-center"
            initial={{ opacity: 0, scale: 0.9 }}
            whileInView={{ opacity: 1, scale: 1 }}
            transition={{ duration: 0.4 }}
            viewport={{ once: true }}
            whileHover={{ scale: 1.05 }}
        >
            <img
                loading="lazy"
                src={image}
                alt=""
                className="max-w-full max-h-full object-contain"
            />
        </motion.div>
    </div>
);

const FeatureList: React.FC = () => {
    const features = [
        {
            icon: collaboration,
            title: "Matching",
            description: "Un cofondateur idéal vous apportera des compétences complémentaires, partagera la charge de travail et renforcera les décisions stratégiques. Ensemble, vous êtes plus forts pour affronter les défis, accélérer la croissance et convaincre les investisseurs. L’union de vos talents maximise les chances de succès de votre projet",
            image: matching2,
            id: "trouver-mon-cofondateur"
        },
        {
            icon: chat,
            title: "Communication integrée",
            description: "Communiquez comme jamais auparavant. Que ce soit par message instantané, appel vidéo ou audio, connectez-vous avec votre équipe où que vous soyez, directement depuis notre plateforme. Éliminez le besoin de solutions tierces et gardez tout le monde sur la même longueur d'onde.",
            image: Com_int,
            id: "communication-integrée"
        },
        {
            icon: management,
            title: "Gestion des taches",
            description: "Gardez le cap sur vos projets, sans effort. Planifiez, assignez et suivez les tâches au sein d'une interface intuitive. Visualisez la progression en temps réel et ajustez vos plans à la volée pour rester toujours un pas devant.",
            image: Gestion_tache,
            id: "gestion-des-taches"
        },
        {
            icon: brain,
            title: "Pleeso optimisée par IA",
            description: "l'IA Pleeso vous aidera à accélérer la création de business plans, pitchs et autres documents essentiels à partir de simples idées. Elle stimulera l’innovation en proposant des idées, des solutions et des designs lors de  vos sessions collaboratives. Enfin, elle facilitera votre prototypage, vous permettant de visualiser vos concepts plus vite et de gagner un temps précieux.",
            image: IA_modele,
            id: "pleeso-optimisée-par-ia"
        }
    ];

    return (
        <div className="w-[calc(100%-2cm)] mx-auto px-8 py-12 max-md:w-full max-md:px-4">
            {features.map((feature, index) => (
                <motion.div
                    id={feature.id}
                    key={index}
                    initial={{ opacity: 0 }}
                    whileInView={{ opacity: 1 }}
                    transition={{ duration: 0.4, delay: index * 0.15 }}
                    viewport={{ once: true }}
                >
                    <FeatureDetail
                        icon={feature.icon}
                        title={feature.title}
                        description={feature.description}
                        image={feature.image}
                        isReversed={index % 2 !== 0}
                    />
                </motion.div>
            ))}
        </div>
    );
};

////////////////////////////// WHY CHOOSE PLEESO COMPONENT //////////////

const WhyChoosePleeso: React.FC = () => {
    const [currentIndex, setCurrentIndex] = useState<number>(0);
    const [touchStart, setTouchStart] = useState<number>(0);
    const [touchEnd, setTouchEnd] = useState<number>(0);

    const containerVariants = {
        hidden: { opacity: 0 },
        visible: {
            opacity: 1,
            transition: {
                staggerChildren: 0.1
            }
        }
    };

    const itemVariants = {
        hidden: { y: 20, opacity: 0 },
        visible: {
            y: 0,
            opacity: 1,
            transition: {
                type: 'spring',
                stiffness: 100
            }
        }
    };

    const advantages = [
        { title: "Algorithme de Matching Avancé", description: "Notre IA analyse en profondeur les profils pour créer des partenariats optimaux." },
        { title: "Réseau International", description: "Accédez à un vivier de talents et d'opportunités du monde entier." },
        { title: "Outils de Collaboration Intégrés", description: "Tout ce dont vous avez besoin pour lancer votre projet, au même endroit." },
        { title: "Support Personnalisé", description: "Une équipe dédiée pour vous accompagner à chaque étape de votre parcours." },
        { title: "Ressources d'Apprentissage", description: "Accédez à des formations exclusives pour développer vos compétences." },
        { title: "Écosystème Complet", description: "De la recherche de co-fondateurs au financement, nous couvrons tous vos besoins." }
    ];

    // Gestionnaires d'événements pour le mobile
    const handleNext = () => setCurrentIndex((prev) => (prev + 1) % advantages.length);
    const handlePrev = () => setCurrentIndex((prev) => (prev - 1 + advantages.length) % advantages.length);

    const handleTouchStart = (e: React.TouchEvent) => {
        setTouchStart(e.touches[0].clientX);
        setTouchEnd(e.touches[0].clientX);
    };

    const handleTouchMove = (e: React.TouchEvent) => setTouchEnd(e.touches[0].clientX);

    const handleTouchEnd = () => {
        const swipeDistance = touchStart - touchEnd;
        if (Math.abs(swipeDistance) > 50) {
            if (swipeDistance > 0) handleNext();
            else handlePrev();
        }
    };

    return (
        <section className="px-16 py-16 relative max-md:px-4 max-md:py-8">
            <h2 className="text-4xl font-bold text-center mb-10 max-md:text-3xl max-md:mb-6">Pourquoi Choisir Pleeso ?</h2>

            {/* Version Desktop */}
            <div className="md:block hidden">
                <motion.div
                    className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8"
                    variants={containerVariants}
                    initial="hidden"
                    animate="visible"
                >
                    {advantages.map((advantage, index) => (
                        <motion.div
                            key={index}
                            className="bg-white rounded-lg p-6 transition-all duration-300 ease-in-out"
                            style={{
                                boxShadow: '0 6px 12px -2px rgba(252, 151, 35, 0.3), 0 3px 7px -3px rgba(252, 151, 35, 0.2)'
                            }}
                            variants={itemVariants}
                            whileHover={{
                                scale: 1.05,
                                boxShadow: '0 10px 20px -5px rgba(252, 151, 35, 0.4), 0 6px 10px -6px rgba(252, 151, 35, 0.3)'
                            }}
                        >
                            <h3 className="text-xl font-semibold mb-2" style={{ color: '#333697' }}>{advantage.title}</h3>
                            <p className="text-gray-600">{advantage.description}</p>
                        </motion.div>
                    ))}
                </motion.div>
            </div>

            {/* Version Mobile avec Carrousel */}
            <div className="block md:hidden">
                <div
                    className="relative overflow-hidden"
                    onTouchStart={handleTouchStart}
                    onTouchMove={handleTouchMove}
                    onTouchEnd={handleTouchEnd}
                >
                    <motion.div
                        className="flex"
                        animate={{
                            x: `-${currentIndex * 100}%`,
                            transition: { type: "spring", stiffness: 300, damping: 30 }
                        }}
                        drag="x"
                        dragConstraints={{ left: 0, right: 0 }}
                        dragElastic={0.1}
                        onDragEnd={(e, { offset }) => {
                            if (Math.abs(offset.x) > 100) {
                                offset.x < 0 ? handleNext() : handlePrev();
                            }
                        }}
                    >
                        {advantages.map((advantage, index) => (
                            <motion.div
                                key={index}
                                className="min-w-full px-2"
                                initial={{ scale: 0.9, opacity: 0.5 }}
                                animate={{
                                    scale: index === currentIndex ? 1 : 0.9,
                                    opacity: index === currentIndex ? 1 : 0.5
                                }}
                                transition={{ duration: 0.3 }}
                            >
                                <div
                                    className="bg-white rounded-lg p-6 min-h-[150px] flex flex-col justify-between mx-auto w-[90%]"
                                    style={{
                                        boxShadow: '0 6px 12px -2px rgba(252, 151, 35, 0.3), 0 3px 7px -3px rgba(252, 151, 35, 0.2)'
                                    }}
                                >
                                    <div>
                                        <h3 className="text-lg font-semibold mb-3" style={{ color: '#333697' }}>
                                            {advantage.title}
                                        </h3>
                                        <p className="text-gray-600 text-sm leading-relaxed">
                                            {advantage.description}
                                        </p>
                                    </div>
                                </div>
                            </motion.div>
                        ))}
                    </motion.div>
                </div>

                {/* Points de navigation */}
                <div className="flex justify-center gap-2 mt-4">
                    {advantages.map((_, index) => (
                        <button
                            key={index}
                            className={`w-2 h-2 rounded-full transition-all duration-300 ${index === currentIndex ? 'bg-[#333697] w-4' : 'bg-gray-300'
                                }`}
                            onClick={() => setCurrentIndex(index)}
                        />
                    ))}
                </div>
            </div>

            {/* Séparateur avec ligne dégradée */}
            <div className="absolute bottom-0 left-0 right-0 h-px bg-gradient-to-r from-transparent via-[#333697]/20 to-transparent" />
        </section>
    );
};

////////////////////////////////////// JOIN SECTION COMPONENT ///////////////////////////////

const JoinSection: React.FC = () => {
    const attributes = ["Entrepreneur", "Porteur de projet", "Salarié", "Etudiant"];
    const [currentAttribute, setCurrentAttribute] = useState(0);
    const navigate = useNavigate();

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentAttribute((prev) => (prev + 1) % attributes.length);
        }, 4000); // Change attribute every 3 seconds

        return () => clearInterval(interval);
    }, []);



    return (
        <section className="px-16 py-16 relative">
            <section className="flex flex-col items-center self-stretch px-8 py-8 mt-8 w-full bg-white max-md:px-4 max-md:py-10 max-md:mt-6">
                <h2 className="text-4xl font-semibold text-center text-black max-md:text-3xl">
                    <span className="whitespace-nowrap">
                        Êtes-vous{' '}
                        <AnimatePresence mode="wait">
                            <motion.span
                                key={currentAttribute}
                                initial={{ opacity: 0 }}
                                animate={{ opacity: 1 }}
                                exit={{ opacity: 0 }}
                                transition={{ duration: 0.4 }}
                                style={{ color: '#333697' }}
                            >
                                {attributes[currentAttribute]}
                            </motion.span>
                        </AnimatePresence>
                        {' '}?
                    </span>
                </h2>
                <AccessButton destination="waitlist"
                    className="mt-8 max-md:mt-6 max-md:px-4"
                // onClick={() => navigate('/waitlist')}
                >
                    Nous rejoindre →
                </AccessButton>
            </section>
            <div className="absolute bottom-0 left-0 right-0 h-px bg-gradient-to-r from-transparent via-[#333697]/20 to-transparent" />
        </section>
    );
};

//////////////////////////////////// STEP COMPONENT //////////////////////////////////////

const Step: React.FC<{ number: string; title: string; description: string; isActive: boolean; isLast: boolean }> = ({
    number,
    title,
    description,
    isActive,
    isLast
}) => (
    <motion.div
        className={`relative bg-[#FEE7CD] rounded-lg p-4 h-full flex flex-col
            ${isActive ? 'border-4 border-[#FC9723]' : ''}
            max-md:min-h-[120px]`}
        initial={{ opacity: 0.5, scale: 0.95 }}
        animate={{
            opacity: isActive ? 1 : 0.5,
            scale: isActive ? 1.05 : 1,
            transition: { duration: 0.5 }
        }}
    >
        <div className="text-xl font-bold text-[#FC9723] mb-2 
            max-md:text-lg">{number}</div>
        <h3 className="text-lg font-semibold mb-2 text-gray-800 
            max-md:text-base max-md:mb-1">{title}</h3>
        <p className="text-sm text-gray-600 flex-grow 
            max-md:text-xs">{description}</p>

        {!isLast && (
            <div className="absolute -right-4 top-1/2 transform -translate-y-1/2 hidden lg:block">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <path d="M5 12H19M19 12L12 5M19 12L12 19" stroke="#FC9723" strokeWidth="2" />
                </svg>
            </div>
        )}
    </motion.div>
);

const StepSection: React.FC = () => {
    const [activeStep, setActiveStep] = useState(0);
    const [direction, setDirection] = useState(1); // 1 pour suivant, -1 pour précédent

    const steps = [
        {
            number: '01',
            title: 'Créez votre profil',
            description: 'Inscrivez-vous, remplissez votre profil et vos préférences'
        },
        {
            number: '02',
            title: 'Explorez les profils',
            description: 'Un algorithme vous suggérera des profils qui vous correspondent'
        },
        {
            number: '03',
            title: 'Matchez avec votre co-fondateur idéal',
            description: 'Une fois trouvé le parfait profil, matchez et laissez la magie opérer'
        },
        {
            number: '04',
            title: 'Communiquez, apprenez et travaillez',
            description: 'Avec l\'ensemble des outils et ressources qu\'offre Pleeso, il ne vous reste que le travail'
        },
        {
            number: '05',
            title: 'Réalisez votre MVP',
            description: 'Grâce à Pleeso, réalisez votre Minimum Viable Product (MVP) ou Go-to-market'
        }
    ];

    useEffect(() => {
        const interval = setInterval(() => {
            setDirection(1);
            setActiveStep((prev) => (prev + 1) % steps.length);
        }, 4000);

        return () => clearInterval(interval);
    }, [steps.length]);

    const handleSwipe = (swipeDirection: number) => {
        if (swipeDirection > 0 && activeStep < steps.length - 1) {
            setDirection(1);
            setActiveStep(prev => prev + 1);
        } else if (swipeDirection < 0 && activeStep > 0) {
            setDirection(-1);
            setActiveStep(prev => prev - 1);
        }
    };

    // Variants pour l'animation de carte qui se retourne
    const flipVariants = {
        enter: (direction: number) => ({
            rotateY: direction > 0 ? 90 : -90,
            opacity: 0,
            scale: 0.9,
            z: -100
        }),
        center: {
            rotateY: 0,
            opacity: 1,
            scale: 1,
            z: 0,
            transition: {
                duration: 0.4,
                ease: "easeOut"
            }
        },
        exit: (direction: number) => ({
            rotateY: direction < 0 ? 90 : -90,
            opacity: 0,
            scale: 0.9,
            z: -100,
            transition: {
                duration: 0.4,
                ease: "easeIn"
            }
        })
    };

    return (
        <section className="bg-white py-8">
            <div className="container mx-auto px-4 max-w-[1200px]">
                <h2 className="text-3xl font-semibold text-center mb-8 
                max-md:text-xl max-md:mb-6">
                    Pleeso c'est seulement 5 étapes !
                </h2>

                <div className="h-[280px] max-md:h-[180px] relative perspective-1000">
                    {/* Version Desktop/Tablet */}
                    <div className="hidden md:flex space-x-4 absolute inset-0">
                        {steps.map((step, index) => (
                            <motion.div
                                key={step.number}
                                className="flex-1"
                                initial={{ y: 50, opacity: 0 }}
                                animate={{ y: 0, opacity: 1 }}
                                transition={{ delay: index * 0.2 }}
                            >
                                <Step
                                    {...step}
                                    isActive={index === activeStep}
                                    isLast={index === steps.length - 1}
                                />
                            </motion.div>
                        ))}
                    </div>

                    {/* Version Mobile avec animation flip card */}
                    <div className="md:hidden absolute inset-0 preserve-3d">
                        <motion.div
                            key={activeStep}
                            custom={direction}
                            variants={flipVariants}
                            initial="enter"
                            animate="center"
                            exit="exit"
                            drag="x"
                            dragConstraints={{ left: 0, right: 0 }}
                            dragElastic={1}
                            onDragEnd={(e, { offset, velocity }) => {
                                const swipe = offset.x * velocity.x;
                                if (Math.abs(swipe) > 50000) {
                                    handleSwipe(swipe);
                                }
                            }}
                            className="absolute inset-0 preserve-3d touch-pan-y"
                        >
                            <Step
                                {...steps[activeStep]}
                                isActive={true}
                                isLast={activeStep === steps.length - 1}
                            />
                        </motion.div>
                    </div>
                </div>

                {/* Indication de progression mobile */}
                <div className="md:hidden text-center mt-4 text-sm text-[#FC9723]">
                    {`${activeStep + 1} / ${steps.length}`}
                </div>
            </div>
        </section>
    );
};

///////////////////////////////////////  QUIZ COMPONENT ///////////////////////////////

const PersonalizationQuiz: React.FC = () => {
    const [step, setStep] = useState(0);
    const [answers, setAnswers] = useState<string[]>([]);
    const navigate = useNavigate();

    const questions = [
        {
            question: "Quel est votre domaine d'expertise principal ?",
            options: ["Ingénierie", "Commercial", "Finance", "Autres"]
        },
        {
            question: "Quel type de co-fondateur recherchez-vous ?",
            options: ["Technique", "Non technique", "Peu importe"]
        },
        {
            question: "À quel stade en est votre projet ?",
            options: ["Idéation", "MVP", "Développement", "Croissance"]
        }
    ];

    const handleAnswer = (answer: string) => {
        setAnswers([...answers, answer]);
        setStep(prev => prev + 1);
    };

    const containerVariants = {
        hidden: { opacity: 0, y: 30 },
        visible: {
            opacity: 1,
            y: 0,
            transition: {
                duration: 0.4,
                ease: "easeOut"
            }
        },
        exit: {
            opacity: 0,
            y: -30,
            transition: {
                duration: 0.3
            }
        }
    };

    return (
        <section className="w-full bg-[#B3B4E5] mt-6 md:mt-22 lg:mt-30">
            {/* Conteneur principal avec hauteur fixe */}
            <div className="max-w-[1440px] mx-auto px-4 lg:px-16">
                {/* Conteneur à hauteur fixe qui maintient une taille constante */}
                <div className="h-[600px] md:h-[600px] lg:h-[700px] flex flex-col justify-center">
                    <motion.div
                        initial="hidden"
                        whileInView="visible"
                        viewport={{ once: true }}
                        className="text-center mb-6 md:mb-8"
                    >
                        <h2 className="text-2xl md:text-3xl lg:text-4xl font-bold text-[#333697] mb-2 md:mb-3">
                            Trouvez Votre Match Parfait
                        </h2>
                        <p className="text-base md:text-lg lg:text-xl text-gray-700">
                            Répondez à quelques questions pour trouver le co-fondateur idéal
                        </p>
                    </motion.div>

                    {/* Conteneur du quiz avec dimensions fixes */}
                    <div className="max-w-xl lg:max-w-2xl mx-auto w-full">
                        <motion.div
                            className="bg-white rounded-xl shadow-lg overflow-hidden"
                            initial={{ opacity: 0, scale: 0.95 }}
                            whileInView={{ opacity: 1, scale: 1 }}
                            transition={{ duration: 0.5 }}
                        >
                            {/* Conteneur de contenu avec hauteur fixe */}
                            <div className="h-[400px] md:h-[420px] lg:h-[450px] flex items-center">
                                <AnimatePresence mode="wait">
                                    {step < questions.length ? (
                                        <motion.div
                                            key={step}
                                            className="p-6 md:p-8 lg:p-10 w-full"
                                            variants={containerVariants}
                                            initial="hidden"
                                            animate="visible"
                                            exit="exit"
                                        >
                                            <h3 className="text-xl md:text-2xl lg:text-2xl font-semibold text-[#333697] mb-6 lg:mb-8 text-center">
                                                {questions[step].question}
                                            </h3>
                                            <div className="space-y-3 md:space-y-4">
                                                {questions[step].options.map((option, index) => (
                                                    <motion.button
                                                        key={index}
                                                        className="w-full py-3 md:py-4 px-4 md:px-6 bg-[#FEE7CD] text-[#333697] rounded-lg hover:bg-[#FC9723] hover:text-white transition-all duration-300 text-sm md:text-base lg:text-lg"
                                                        whileHover={{ scale: 1.01 }}
                                                        whileTap={{ scale: 0.99 }}
                                                        onClick={() => handleAnswer(option)}
                                                    >
                                                        {option}
                                                    </motion.button>
                                                ))}
                                            </div>
                                            <div className="mt-6 lg:mt-8 flex justify-center gap-1.5">
                                                {questions.map((_, index) => (
                                                    <div
                                                        key={index}
                                                        className={`h-1 md:h-1.5 rounded-full transition-all duration-300 ${index === step
                                                            ? 'w-6 md:w-8 bg-[#FC9723]'
                                                            : 'w-1.5 md:w-2 bg-[#FEE7CD]'
                                                            }`}
                                                    />
                                                ))}
                                            </div>
                                        </motion.div>
                                    ) : (
                                        <motion.div
                                            className="p-6 md:p-8 lg:p-10 text-center w-full"
                                            variants={containerVariants}
                                            initial="hidden"
                                            animate="visible"
                                        >
                                            <motion.div
                                                initial={{ scale: 0 }}
                                                animate={{ scale: 1 }}
                                                transition={{ duration: 0.5, type: "spring" }}
                                                className="w-16 md:w-20 h-16 md:h-20 bg-green-100 rounded-full mx-auto mb-4 md:mb-6 flex items-center justify-center"
                                            >
                                                <svg className="w-8 md:w-10 h-8 md:h-10 text-green-500" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                                                </svg>
                                            </motion.div>
                                            <h3 className="text-xl md:text-2xl lg:text-3xl font-semibold text-[#333697] mb-3 md:mb-4">
                                                Merci pour vos réponses !
                                            </h3>
                                            <p className="text-sm md:text-base lg:text-lg text-gray-600 mb-6 md:mb-8">
                                                Nous avons trouvé plusieurs matchs potentiels qui correspondent à votre profil.
                                            </p>
                                            <motion.button
                                                onClick={() => navigate('/waitlist')}
                                                className="px-6 md:px-8 py-2.5 md:py-3 bg-[#FC9723] text-white rounded-full hover:bg-[#333697] transition-colors duration-300 text-sm md:text-base lg:text-lg"
                                                whileHover={{ scale: 1.03 }}
                                                whileTap={{ scale: 0.97 }}
                                            >
                                                Voir mes matchs
                                            </motion.button>
                                        </motion.div>
                                    )}
                                </AnimatePresence>
                            </div>
                        </motion.div>
                    </div>
                </div>
            </div>
        </section>
    );
};

///////////////////////////////// TESTIMONY COMPONENT //////////////////////////////////

const Testimonial: React.FC<{ content: string; author: string; location: string; image: string }> = ({ content, author, location, image }) => (
    <div className="flex flex-wrap gap-10 items-center p-8 bg-[#B3B4E5] rounded-3xl shadow-lg h-[400px] w-full 
        max-md:flex-col max-md:h-[400px] max-md:p-4 max-md:gap-4">
        {/* Image containeur avec ratio fixe */}
        <div className="relative min-w-[368px] h-[320px]
          max-md:w-full max-md:min-w-0 max-md:h-[180px]">
            <img
                loading="lazy"
                src={image}
                alt={`Portrait of ${author}`}
                className="absolute w-full h-full object-cover object-center rounded-lg"
            />
        </div>
        {/* Conteneur du contenu et infos auteur */}
        <div className="flex flex-col flex-1 h-full overflow-y-auto
          max-md:w-full max-md:gap-3">
            <p className="text-lg text-[#333697] 
            max-md:text-xs max-md:leading-normal">{content}</p>
            <div className="mt-auto pt-4 text-[#333697] 
            max-md:mt-2 max-md:pt-0">
                <span className="font-semibold 
              max-md:text-[11px]">{author}</span>
                <br />
                <span className="italic 
              max-md:text-[10px]">{location}</span>
            </div>
        </div>
    </div>
);

const TestimonialSection: React.FC = () => {
    const testimonials = [
        {
            content: "Je cherchais un associé business depuis des mois. En 2 semaines sur Pleeso, j'ai trouvé Sarah, une ancienne consultante qui complète parfaitement mes compétences. L'algorithme de matching a vraiment fait la différence, il a détecté une compatibilité que je n'aurais pas vue sur LinkedIn.",
            author: "Michael, Senior Developer & CTO",
            location: "Paris, France",
            image: Kab_image
        },
        {
            content: "Grâce à Pleeso, j'ai trouvé le partenaire technique idéal pour mon projet. La plateforme nous a permis de collaborer efficacement dès le début, ce qui a accéléré considérablement notre développement.",
            author: "Sophie, UX Designer",
            location: "Lyon, France",
            image: Sophie
        },
        {
            content: "Ce qui m'a surpris avec Pleeso, c'est l'écosystème complet. Au-delà du matching avec mon co-fondateur, nous avons eu accès à des templates de pitch, des retours d'entrepreneurs expérimentés, et surtout un réseau d'investisseurs pertinent. C'est bien plus qu'une simple plateforme de mise en relation.",
            author: "Ahmed, Ingénieur IA",
            location: "Marseille, France",
            image: Nicolas
        }
    ];

    const [currentTestimonial, setCurrentTestimonial] = useState(0);

    const nextTestimonial = () => {
        setCurrentTestimonial((prev) => (prev + 1) % testimonials.length);
    };

    const prevTestimonial = () => {
        setCurrentTestimonial((prev) => (prev - 1 + testimonials.length) % testimonials.length);
    };

    return (
        <section className="px-10 py-10 relative max-md:px-4 max-md:py-6">
            <section className="flex flex-col items-center self-center mt-16 px-5 py-5 bg-white max-md:mt-4 max-md:px-2 max-md:py-3">
                <h2 className="text-4xl text-[#333697] mb-10 text-center max-md:text-2xl max-md:mb-6">
                    Ce que <span className="font-semibold text-[#FC9723]">disent</span> nos utilisateurs
                </h2>
                <div className="relative w-full max-w-4xl h-[400px] max-md:h-[400px] max-md:max-w-[95%]">
                    <Testimonial {...testimonials[currentTestimonial]} />
                    <button
                        onClick={prevTestimonial}
                        className="absolute left-0 top-1/2 transform -translate-y-1/2 -translate-x-1/2 w-12 h-12 bg-white text-[#FC9723] rounded-full shadow-lg hover:bg-[#FC9723] hover:text-white transition-colors duration-300 flex items-center justify-center max-md:w-6 max-md:h-6 max-md:-translate-x-1"
                        aria-label="Témoignage précédent"
                    >
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" className="w-6 h-6 max-md:w-3 max-md:h-3">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 19l-7-7 7-7" />
                        </svg>
                    </button>
                    <button
                        onClick={nextTestimonial}
                        className="absolute right-0 top-1/2 transform -translate-y-1/2 translate-x-1/2 w-12 h-12 bg-white text-[#FC9723] rounded-full shadow-lg hover:bg-[#FC9723] hover:text-white transition-colors duration-300 flex items-center justify-center max-md:w-6 max-md:h-6 max-md:translate-x-1"
                        aria-label="Témoignage suivant"
                    >
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" className="w-6 h-6 max-md:w-3 max-md:h-3">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
                        </svg>
                    </button>
                </div>
                <div className="flex mt-8 max-md:mt-3">
                    {testimonials.map((_, index) => (
                        <button
                            key={index}
                            onClick={() => setCurrentTestimonial(index)}
                            className={`w-3 h-3 mx-2 rounded-full transition-colors duration-300 ${index === currentTestimonial ? 'bg-[#FC9723]' : 'bg-[#B3B4E5]'} max-md:w-1.5 max-md:h-1.5 max-md:mx-1`}
                            aria-label={`Aller au témoignage ${index + 1}`}
                        />
                    ))}
                </div>
            </section>
            <div className="absolute bottom-0 left-0 right-0 h-px bg-gradient-to-r from-transparent via-[#333697]/20 to-transparent" />
        </section>
    );
};

////////////////////////////////////// COMMUNITY COMPONENT ////////////////////////////

const CommunityFeature: React.FC<{ icon: string; title: string; description: string }> = ({ icon, title, description }) => (
    <div className="w-full aspect-square"> {/* Container carré pour garantir des dimensions uniformes */}
        <div className="flex flex-col items-center justify-between h-full p-3 md:p-6 bg-white rounded-2xl">
            {/* Container icône avec taille fixe */}
            <div className="flex items-center justify-center w-12 h-12 md:w-[72px] md:h-[72px] bg-orange-100 rounded">
                <img
                    loading="lazy"
                    src={icon}
                    alt=""
                    className="object-contain w-8 h-8 md:w-[40px] md:h-[40px]"
                />
            </div>

            {/* Container texte centré verticalement et horizontalement */}
            <div className="flex flex-col items-center flex-1 justify-center w-full">
                <h3 className="text-sm md:text-lg font-semibold md:font-bold text-zinc-800 text-center">
                    {title}
                </h3>
                <p className="mt-1 md:mt-4 text-[12px] md:text-lg text-center w-full 
              max-w-[120px] md:max-w-[272px] text-zinc-800 leading-tight md:leading-normal">
                    {description}
                </p>
            </div>
        </div>
    </div>
);

const CommunitySection: React.FC = () => {
    const features = [
        {
            icon: Elearning,
            title: "E-learning",
            description: "L'entreprenariat s'apprend sur Pleeso !"
        },
        {
            icon: Reseau,
            title: "Réseaux sociaux",
            description: "Nous sommes présents sur vos réseaux préférés !"
        },
        {
            icon: Blog_image,
            title: "Blog",
            description: "Ils sont actifs n'hésitez pas à rejoindre les pleesoseurs !"
        },
        {
            icon: Produit,
            title: "Mise à jour produit",
            description: "Nous n'avons jamais fini de vous surprendre !"
        }
    ];

    return (
        <section className="px-3 md:px-16 py-6 md:py-16 relative">
            <section className="flex flex-col items-center mt-2 md:mt-16 w-full">
                <h2 className="text-xl md:text-4xl text-center">
                    <span className="text-[#333333]">Rejoindre</span>{" "}
                    <span className="font-semibold text-orange-400">la communauté Pleeso !</span>
                </h2>
                <p className="mt-2 md:mt-5 text-xs md:text-lg text-center 
          max-w-[90%] md:max-w-[800px] text-zinc-800 leading-relaxed">
                    Rejoignez une communauté forte de porteurs de projets et entrepreneurs et investisseurs.
                    Passez votre stratégie d'entrepreneuriat à la vitesse supérieure avec des conseils et de l'e-learning.
                </p>

                {/* Grid container avec espacement réduit sur mobile */}
                <div className="grid grid-cols-2 md:grid-cols-4 gap-2 md:gap-8 mt-4 md:mt-14 
          w-full max-w-[1280px]">
                    {features.map((feature, index) => (
                        <CommunityFeature key={index} {...feature} />
                    ))}
                </div>
            </section>
            <div className="absolute bottom-0 left-0 right-0 h-px bg-gradient-to-r from-transparent via-[#333697]/20 to-transparent" />
        </section>
    );
};


///////////////////////////////// FAQ COMPONENT /////////////////////////////////

const FAQItem: React.FC<{ question: string; answer: string }> = ({ question, answer }) => {
    const [isOpen, setIsOpen] = useState(false);

    return (
        <div className="flex flex-col mt-4 w-full text-zinc-800 max-md:mt-2 max-md:max-w-full">
            <button
                className="flex flex-wrap gap-10 justify-between items-center p-4 w-full bg-orange-100 rounded-lg text-left
          max-md:p-3 max-md:gap-4"
                onClick={() => setIsOpen(!isOpen)}
                aria-expanded={isOpen}
            >
                <span className="max-md:text-sm max-md:leading-tight max-md:flex-1">{question}</span>
                <img
                    loading="lazy"
                    src={deroulant}
                    alt={isOpen ? "Collapse" : "Expand"}
                    className={`object-contain shrink-0 w-6 aspect-square transition-transform 
              max-md:w-4 ${isOpen ? 'rotate-180' : ''}`}
                />
            </button>
            {isOpen && (
                <div className="p-4 mt-2 bg-white rounded-lg
          max-md:p-3 max-md:mt-1">
                    <p className="max-md:text-xs max-md:leading-relaxed">{answer}</p>
                </div>
            )}
        </div>
    );
};

const FAQSection: React.FC = () => {
    const faqs = [
        {
            question: "Comment fonctionne le matchmaking de Pleeso ?",
            answer: "Le matchmaking de Pleeso utilise un algorithme avancé qui prend en compte vos compétences, expériences, et objectifs pour vous suggérer des cofondateurs potentiels qui correspondent le mieux à vos besoins et à votre vision."
        },
        {
            question: "Pleeso est-il gratuit ?",
            answer: "Pleeso offre un plan de base totalement gratuit qui vous permet d'accéder à de nombreuses fonctionnalités, matching, ressources etc.. Nous proposons également des plans premium avec des fonctionnalités avancées pour répondre aux besoins des entrepreneurs les plus ambitieux."
        },
        {
            question: "Mes données sont-elles sécurisées ?",
            answer: "Absolument. Chez Pleeso, la sécurité de vos données est notre priorité. Nous utilisons des protocoles de cryptage avancés et respectons strictement les réglementations en matière de protection des données, y compris le RGPD."
        },
        {
            question: "Puis-je utiliser Pleeso à l'étranger ?",
            answer: "Oui, Pleeso est une plateforme globale accessible depuis n'importe où dans le monde. Nous encourageons les connexions internationales et offrons des outils pour faciliter la collaboration à distance."
        }
    ];

    return (
        <section className="flex gap-10 justify-center px-56 mt-32 text-lg 
      max-md:px-3 max-md:mt-8 max-md:gap-4">
            <div className="flex flex-col flex-1 shrink justify-center w-full basis-0 min-w-[240px] max-md:max-w-full">
                <h2 className="text-center text-4xl text-zinc-800 
          max-md:text-xl max-md:font-semibold">
                    On répond à vos questions
                </h2>
                <div className="flex flex-col mt-14 w-full text-zinc-800 
          max-md:mt-6 max-md:max-w-full">
                    {faqs.map((faq, index) => (
                        <FAQItem key={index} question={faq.question} answer={faq.answer} />
                    ))}
                </div>
                <div className="flex flex-col items-center justify-center mt-14 w-full 
          max-md:mt-8 max-md:max-w-full">
                    <p className="text-center text-zinc-800 
              max-md:text-sm max-md:px-4">
                        Une question ou une suggestion ? N'hésitez pas, écrivez-nous !
                    </p>
                    <AccessButton destination="contact" variant="secondary" className="px-18 py-4 mt-6 bg-indigo-800 border border-white border-solid min-h-[60px] rounded-[40px] text-sm text-white 
              max-md:px-6 max-md:py-3 max-md:mt-4 max-md:min-h-[44px] max-md:text-sm hover:bg-[#FC9723] transitions-color">
                        Contacter l'équipe
                    </AccessButton>
                </div>
            </div>
        </section>
    );
};

/////////////////////////////////// BLOG COMPONENT /////////////////////////

const BlogSection: React.FC = () => {
    const blogPosts = [
        {
            id: 1,
            title: "L'importance de la résilience dans l'entrepreneuriat moderne",
            category: "Résilience",
            excerpt: "💼 La résilience est cruciale pour les entrepreneurs dans un environnement incertain et concurrentiel. Des experts comme Jay Shetty, Tony Robbins et Simon Sinek suggèrent d'adopter une mentalité de croissance, de pratiquer la pleine conscience, de définir une vision claire, de s'entourer de soutien, de rester adaptable et de cultiver la gratitude. Ces pratiques aident les entrepreneurs à surmonter les défis et à transformer les crises en opportunités de croissance, assurant ainsi la pérennité et le succès de leurs entreprises.",
            image: resilience,
            date: "15 Oct 2024",
            link: "https://www.linkedin.com/pulse/limportance-de-la-résilience-dans-lentrepreneuriat-moderne-pleeso-9ji1e/?trackingId=Q%2BheL6V%2FRhWifJ6dTA2EZA%3D%3D"
        },
        {
            id: 2,
            title: "Comment choisir le bon cofondateur en 2024 avec les outils adaptés ?",
            category: "Tools",
            excerpt: "En tant qu'entrepreneur, on ne saurait trop insister sur l'importance de choisir le bon cofondateur. Le bon partenaire ne partagera pas seulement votre passion pour votre projet, mais complétera également vos compétences, garantissant ainsi une relation fructueuse.",
            image: chooseCofounder,
            date: "27 août 2024",
            link: "https://www.linkedin.com/pulse/comment-choisir-le-bon-cofondateur-en-2024-avec-les-outils-adapt%C3%A9s-el1se/?trackingId=8MkoIh4EcHkZDyy5jg6gkg%3D%3D"
        },
        {
            id: 3,
            title: "Tendances tech 2024",
            category: "Tech trends",
            excerpt: "Un aperçu des technologies émergentes qui pourraient révolutionner le paysage des startups cette année.",
            image: trendTech,
            date: "1 Avr 2024",
            link: "https://www.linkedin.com/pulse/les-tendances-tech-2024-opportunit%C3%A9s-pour-entrepreneurs-pleeso-o4mme/?trackingId=CM5f98ItYDMw2VeHBEZgmw%3D%3D"
        },
        {
            id: 4,
            title: "Financement: les clés du succès",
            category: "Finance",
            excerpt: "Apprenez les stratégies essentielles pour sécuriser le financement nécessaire au lancement et à la croissance de votre startup.",
            image: financingSuccess,
            date: "7 Nov 2024",
            link: "https://www.linkedin.com/pulse/financer-son-projet-les-cl%C3%A9s-du-succ%C3%A8s-pleeso-qn1fe/?trackingId=Jw0decvZgBk93%2Fqv14zzgw%3D%3D"
        }
    ];

    const containerVariants = {
        hidden: { opacity: 0 },
        visible: {
            opacity: 1,
            transition: {
                staggerChildren: 0.1
            }
        }
    };

    const itemVariants = {
        hidden: { opacity: 0, y: 20 },
        visible: {
            opacity: 1,
            y: 0,
            transition: {
                duration: 0.5
            }
        }
    };

    return (
        <section className="w-full bg-white">
            <div className="max-w-[1440px] mx-auto px-5 lg:px-20 py-16 md:py-24">
                <motion.div
                    variants={containerVariants}
                    initial="hidden"
                    whileInView="visible"
                    viewport={{ once: true }}
                >
                    <motion.h2
                        className="text-3xl md:text-4xl font-bold text-center mb-12"
                        variants={itemVariants}
                    >
                        Derniers articles du blog
                    </motion.h2>

                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6 mb-12">
                        {blogPosts.map((post) => (
                            <motion.article
                                key={post.id}
                                className="bg-[#FEE7CD] rounded-lg overflow-hidden shadow-md hover:shadow-lg transition-shadow duration-300"
                                variants={itemVariants}
                                whileHover={{ y: -5 }}
                            >
                                <div className="relative h-48 overflow-hidden">
                                    <img
                                        src={post.image}
                                        alt={post.title}
                                        className="w-full h-full object-cover transform hover:scale-105 transition-transform duration-300"
                                    />
                                </div>
                                <div className="p-6">
                                    <span className="text-[#FC9723] text-sm font-semibold">
                                        {post.category}
                                    </span>
                                    <h3 className="text-lg font-semibold mt-2 mb-3 overflow-hidden text-ellipsis line-clamp-2">
                                        {post.title}
                                    </h3>
                                    <p className="text-gray-600 text-sm mb-4 overflow-hidden text-ellipsis line-clamp-2">
                                        {post.excerpt}
                                    </p>
                                    <div className="flex justify-between items-center text-sm">
                                        <span className="text-gray-500">{post.date}</span>
                                        <a
                                            href={post.link} target="_blank"
                                            className="text-[#FC9723] font-semibold hover:text-[#333697] transition-colors duration-300"
                                        >
                                            Lire la suite
                                        </a>
                                    </div>
                                </div>
                            </motion.article>
                        ))}
                    </div>

                    <motion.div
                        className="text-center"
                        variants={itemVariants}
                    >
                        <a
                            href="https://www.linkedin.com/company/pleeso/posts/?feedView=all" target="_blank"
                            className="inline-block px-8 py-3 bg-[#333697] text-white rounded-full hover:bg-[#FC9723] transition-colors duration-300"
                        >
                            Voir tous les articles
                        </a>
                    </motion.div>
                </motion.div>
            </div>
        </section>
    );
};


const LandingPage: React.FC = () => {
    return (
        <>
            <Hero />
            <FeatureSection />
            {/* <ProcessExplanation /> */}
            <CallToAction />
            <FeatureList />
            <ContentWrapper>
                <WhyChoosePleeso />
            </ContentWrapper>
            <JoinSection />
            <ContentWrapper>
                <StepSection />
            </ContentWrapper>
            <PersonalizationQuiz />
            <TestimonialSection />
            <CommunitySection />
            <FAQSection />
            <BlogSection />
            {/* </ContentWrapper> */}
        </>
    );
};

export async function landingLoader({ request }: LoaderFunctionArgs) {

    const searchParams = new URL(request.url).searchParams;

    if (!!searchParams.get("activated")) {
        return redirect("/activated");
    }
    return null;
}

export default LandingPage;
