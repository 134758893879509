import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios";

export abstract class RestApi {
    client: AxiosInstance;
    constructor(baseURL: string) {
        this.client = axios.create({ baseURL });
    }

    protected async post(path: string, data: any, headers: any = {}): Promise<AxiosResponse> {
        const config: AxiosRequestConfig = {
            headers: {
                ...headers,
                'Accept': 'application/json'
            },
        };

        const response: AxiosResponse = await this.client.post(path, data, config);

        return response;
    }

    protected async get(path: string, headers: any = {}): Promise<AxiosResponse> {
        const config: AxiosRequestConfig = {
            headers: {
                ...headers,
                'Accept': 'application/json'
            },
        };

        const response: AxiosResponse = await this.client.get(path, config);

        return response;
    }

    protected async put(path: string, data: any, headers: any = {}): Promise<AxiosResponse> {
        const config: AxiosRequestConfig = {
            headers: {
                ...headers,
                'Accept': 'application/json'
            },
        };

        const response: AxiosResponse = await this.client.put(path, data, config);

        return response;
    }

    protected async delete(path: string, headers: any = {}): Promise<AxiosResponse> {
        const config: AxiosRequestConfig = {
            headers: {
                ...headers,
                'Accept': 'application/json'
            },
        };

        const response: AxiosResponse = await this.client.delete(path, config);

        return response;
    }
}