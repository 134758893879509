import './App.css';
import { createBrowserRouter, Link, Outlet, redirect, RouterProvider, useLocation, useNavigate } from 'react-router-dom';
import { authProvider } from '../service/AuthProvider';
import { userLoader, Dashboard } from './dashboard/Dashboard';
import { loginAction, loginLoader, LoginPage } from './login/Login';
import { Signup } from './signup/Signup'
import { Messages } from './messages/Messages';
import LandingPage, { landingLoader } from './landing/Landing';
import { Facebook, Twitter, Instagram, Linkedin, ChevronDown, Menu, Check } from 'lucide-react';
import Logo_Pleeso from '../assets/images/Logo_Pleeso.svg';
import { motion, AnimatePresence } from 'framer-motion';
import { useEffect, useState } from 'react';
import { AccessButton, ButtonDestination } from '../components/AccessButon';
import WaitlistPage from './waitlist/Waitlist';
import ContactPage from './contact/Contact';
import BlogPage from './blog/Blog';
import { newContactApi } from '../service/api/public/NewContactApi';
import ActivatedPage from './activated/Activated';

const router = createBrowserRouter([
  {
    id: "root",
    path: "/",
    loader() {
      return { tokenDetails: authProvider.getTokenDetails() };
    },
    Component: Layout,
    children: [
      {
        index: true,
        loader: landingLoader,
        Component: LandingPage,
      },
      {
        path: "waitlist",
        Component: WaitlistPage
      },
      {
        path: "contact",
        Component: ContactPage
      },
      {
        path: "login",
        action: loginAction,
        loader: loginLoader,
        Component: LoginPage,
      },
      {
        path: "dashboard",
        loader: userLoader,
        Component: Dashboard,
      },
      {
        path: "signup/*",
        Component: Signup,
      },
      {
        path: "messages",
        Component: Messages,
      },
      {
        path: "blog",
        Component: BlogPage
      },
      {
        path: "activated",
        Component: ActivatedPage
      }
    ],
  },
  {
    path: "/logout",
    async action() {
      // We signout in a "resource route" that we can hit from a fetcher.Form
      await authProvider.signout();
      return redirect("/");
    },
  },
]);

const solutionsItems = [
  'Trouver mon cofondateur',
  'Communication integrée',
  'Gestion des taches',
  'Pleeso optimisée par IA'
];

function Layout() {
  return (
    <>
      <Header />
      <main className="pt-16"> {/* Ajout d'un padding-top pour compenser le header fixe */}
        <Outlet />
      </main>
      <Footer />
    </>
  );
}

const MainLinks: React.FC = () => {
  const location = useLocation();
  let links: ButtonDestination[] = ["home"];

  if (location.pathname === "/") {
    links = ["waitlist"];
  }

  return (<div className="flex items-center gap-4">
    {links.map(link => (
      <AccessButton key={link}
        destination={link}
        className="text-sm whitespace-nowrap"
        variant="primary"
      />
    ))}
  </div>
  )
}

///////////////////////////// COMPOSANT HEADER ///////////////////////
const Header: React.FC = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isSolutionsOpen, setIsSolutionsOpen] = useState(false);
  const [isVisible, setIsVisible] = useState(true);
  const [lastScrollY, setLastScrollY] = useState(0);
  const [hasScrolled, setHasScrolled] = useState(false);
  const navigate = useNavigate();
  useEffect(() => {
    const handleScroll = () => {
      const currentScrollY = window.scrollY;

      // Mettre à jour hasScrolled basé sur la position de défilement
      setHasScrolled(currentScrollY > 0);

      if (currentScrollY <= 0) {
        setIsVisible(true);
      } else if (currentScrollY < lastScrollY) {
        setIsVisible(true);
      } else if (currentScrollY > lastScrollY) {
        setIsVisible(false);
      }

      setLastScrollY(currentScrollY);
    };

    window.addEventListener('scroll', handleScroll, { passive: true });
    return () => window.removeEventListener('scroll', handleScroll);
  }, [lastScrollY]);

  const handleLinkClick = () => {
    setIsMenuOpen(false);
    setIsSolutionsOpen(false);
  };

  const handleNavigateToHome = () => {
    navigate("/");
    window.scrollTo(0, 0); // Scroll to top of the page
  };

  return (
    <AnimatePresence>
      <motion.div
        initial={{ y: 0 }}
        animate={{
          y: isVisible ? 0 : -100,
          opacity: isVisible ? 1 : 0
        }}
        transition={{ duration: 0.3 }}
        className={`fixed top-0 left-0 right-0 z-50 bg-white/95 backdrop-blur-sm
          ${hasScrolled ? 'border-b border-gray-200 shadow-sm' : ''} 
          transition-all duration-300`}
      >
        <header className="relative flex items-center justify-between w-full max-w-[1600px] mx-auto bg-white min-h-[56px] sm:min-h-[64px] px-5 lg:px-20 py-2">
          {/* Logo and Navigation */}
          <div className="flex items-center gap-3 sm:gap-4 md:gap-8 flex-grow md:flex-grow-0">
            <button onClick={handleNavigateToHome} className="flex gap-0 sm:gap-0.5 items-center whitespace-nowrap">
              <img
                src={Logo_Pleeso}
                alt="Pleeso Logo"
                className="w-10 sm:w-12 md:w-14 aspect-square"
              />
              <div className="text-[#333697] text-xl sm:text-2xl font-semibold">
                pleeso
              </div>
            </button>

            {/* Desktop Navigation */}
            <nav className="hidden md:flex gap-4 lg:gap-8 items-center text-base lg:text-lg text-[#333697] font-semibold">
              <div className="relative group">
                <button className="group-hover:text-[#FC9723] flex items-center gap-1">
                  Solutions
                  <ChevronDown
                    size={20}
                    className="transform group-hover:rotate-180 transition-transform duration-200"
                  />
                </button>
                <div className="absolute top-full left-0 bg-white shadow-md rounded-md py-2 mt-1 z-10 hidden group-hover:block w-56">
                  {solutionsItems.map((item, index) => (
                    <a
                      key={index}
                      href={`#${item.toLowerCase().replace(/ /g, '-')}`}
                      className="block px-4 py-2 hover:bg-[#FC9723] hover:text-white transition-colors duration-200 text-sm font-normal text-zinc-800"
                      onClick={handleLinkClick}
                    >
                      {item}
                    </a>
                  ))}
                </div>
              </div>
              {/* <Link
                to="/blog"
                className="hover:text-[#FC9723] transition-colors duration-200"
              >
                Blog
              </Link> */}
            </nav>
          </div>

          {/* Desktop Access Button */}
          {/* <div className="hidden md:block">
            <AccessButton destination="waitlist" className="min-h-[44px]" />
          </div> */}

          {/* Mobile Controls */}
          <div className="flex items-center gap-4">
            <MainLinks />
            <button
              onClick={() => setIsMenuOpen(!isMenuOpen)}
              className="p-2 text-[#333697] hover:bg-gray-100 rounded-md transition-colors duration-200 block md:hidden"
              aria-label="Menu"
            >
              <Menu size={24} />
            </button>
          </div>

          {/* Mobile Menu */}
          {isMenuOpen && (
            <div className="absolute top-full left-0 right-0 bg-white shadow-lg z-50 md:hidden">
              <div className="flex flex-col py-2">
                <div className="border-b border-gray-100">
                  <button
                    className="flex items-center justify-between w-full px-4 py-2.5 text-[#333697] font-semibold hover:text-[#FC9723] transition-colors duration-200"
                    onClick={() => setIsSolutionsOpen(!isSolutionsOpen)}
                  >
                    Solutions
                    <ChevronDown
                      size={18}
                      className={`transform transition-transform duration-200 ${isSolutionsOpen ? 'rotate-180' : ''
                        }`}
                    />
                  </button>
                  <div className={`overflow-hidden transition-all duration-200 ${isSolutionsOpen ? 'max-h-48' : 'max-h-0'
                    }`}>
                    <div className="px-4 py-2 bg-gray-50">
                      {solutionsItems.map((item, index) => (
                        <a
                          key={index}
                          href={`#${item.toLowerCase().replace(/ /g, '-')}`}
                          className="block py-2 text-sm text-zinc-800 hover:text-[#FC9723] transition-colors duration-200"
                          onClick={handleLinkClick}
                        >
                          {item}
                        </a>
                      ))}
                    </div>
                  </div>
                </div>
                {/* <Link
                  to="/blog"
                  className="px-4 py-2.5 text-[#333697] font-semibold hover:text-[#FC9723] transition-colors duration-200"
                  onClick={handleLinkClick}
                >
                  Blog
                </Link> */}
              </div>
            </div>
          )}
        </header>
      </motion.div>
    </AnimatePresence>
  );
};

//////////////////////////////////////// FOOTER COMPONENT /////////////////////////
interface FooterLinkProps {
  title: string;
  links: string[];
}

const FooterLinkSection: React.FC<FooterLinkProps> = ({ title, links }) => (
  <div className="flex flex-col w-auto">
    <h3 className="text-lg font-semibold text-indigo-800">{title}</h3>
    <ul className="flex flex-col mt-6 w-full text-base text-zinc-800">
      {links.map((link, index) => (
        <li key={index} className={index > 0 ? "mt-4" : ""}>{link}</li>
      ))}
    </ul>
  </div>
);

const Footer = () => {
  const [email, setEmail] = useState<string>();
  const [status, setStatus] = useState<'idle' | 'loading' | 'success' | 'error'>('idle');

  const handleSubmitNewsletter = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      if (email) {
        setStatus("loading");
        await newContactApi.addToNewsletter(email);
        setStatus("success");
      }
    } catch {
      setStatus("error")
    }
  };

  const handleEmailInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setEmail(value);
  };

  return (
    <footer className="bg-gray-100 py-10">
      <div className="container mx-auto px-4">
        <div className="grid grid-cols-10 gap-4 mb-8">
          {/* Logo et Description à l'extrême gauche */}
          <div className="col-span-12 md:col-span-2">
            <div className="flex items-center mb-4">
              <img loading="lazy" src={Logo_Pleeso} alt="Pleeso Logo" className="object-contain shrink-0 self-stretch my-auto w-12 rounded-full aspect-square" />
              <h3 className="text-2xl font-bold text-[#333697]">PLEESO</h3>
            </div>
            <p className="text-base mb-4">Trouvez votre co-fondateur idéal et lancez votre startup avec succès.</p>
          </div>

          {/* Menu Items avec 4 colonnes */}
          <div className="col-span-12 md:col-span-5">
            <div className="grid grid-cols-2 gap-4">
              {/* Solutions */}
              <div>
                <h3 className="font-semibold text-[17px] mb-3">Solutions</h3>
                <ul className="text-[15px] space-y-2">
                  {solutionsItems.map((item, index) => (
                    <li key={index}><a
                      href={`#${item.toLowerCase().replace(/ /g, '-')}`}
                      className="hover:text-[#FC9723]"
                    >
                      {item}
                    </a></li>
                  ))}
                </ul>
              </div>

              {/* Ressources */}
              {/* <div>
                <h3 className="font-semibold text-[17px] mb-3">Ressources</h3>
                <ul className="text-[15px] space-y-2">
                  <li><a href="#" className="hover:text-[#FC9723]">Boîte à outils</a></li>
                  <li><a href="#" className="hover:text-[#FC9723]">E-learning</a></li>
                  <li><a href="#" className="hover:text-[#FC9723]">Webinaires</a></li>
                </ul>
              </div> */}

              {/* Blog */}
              {/* <div>
                <h3 className="font-semibold text-[17px] mb-3">Blog</h3>
                <ul className="text-[15px] space-y-2">
                  <li><a href="#" className="hover:text-[#FC9723]">Startup advice</a></li>
                  <li><a href="#" className="hover:text-[#FC9723]">Success stories</a></li>
                  <li><a href="#" className="hover:text-[#FC9723]">Tech trends</a></li>
                  <li><a href="#" className="hover:text-[#FC9723]">Finance</a></li>
                  <li><a href="#" className="hover:text-[#FC9723]">Updates</a></li>
                </ul>
              </div> */}

              {/* Contact */}
              <div>
                <h3 className="font-semibold text-[17px] mb-3">Nous contacter</h3>
                <ul className="text-[15px] space-y-2">
                  <li><a href="mailto:contact@pleeso.com" className="hover:text-[#FC9723] pr-2">contact@pleeso.com</a></li>
                  {/* <li><a href="tel:+33123456789" className="hover:text-[#FC9723]">+33 1 23 45 67 89</a></li> */}
                </ul>
              </div>
            </div>
          </div>

          {/* Newsletter à l'extrême droite */}
          <div className="col-span-12 md:col-span-3">
            <h3 className="font-semibold text-[17px] mb-3">Newsletter</h3>
            <p className="text-[15px] mb-2">Restez informé de nos dernières actualités</p>
            {status === 'success' ? (
              <Check className="w-8 h-8 text-green-500" />
            ) : (
              <form onSubmit={handleSubmitNewsletter} className="flex flex-col max-w-full">
                {status === 'error' && (
                  <div className="block text-sm font-bold text-red-700 mb-1">
                    * Cette adresse e-mail est déjà dans la liste
                  </div>
                )}
                <input
                  type="email"
                  placeholder="Votre email"
                  className="w-full p-3 rounded-md border border-gray-300 focus:outline-none focus:border-[#FC9723] mb-2.5 text-sm"
                  value={email}
                  onChange={handleEmailInputChange}
                />
                <button
                  type="submit"
                  disabled={status === 'loading'}
                  className="w-full bg-[#333697] text-white px-6 py-3 rounded-md hover:bg-[#FC9723] transitions-color transition duration-300 text-sm font-medium"
                >
                  {status === 'loading' ? (
                    <div className="w-6 h-6 border-2 border-white border-t-transparent rounded-full animate-spin" />
                  ) : (
                    <>
                      S'inscrire
                    </>
                  )}

                </button>
              </form>
            )}

          </div>
        </div>

        <div className="flex flex-col sm:flex-row justify-between items-center pt-6 border-t border-gray-300">
          <div className="text-[15px] text-base text-gray-600 order-2 lg:order-1 mt-6 lg:mt-6">
            © {new Date().getFullYear()} Pleeso. Tous droits réservés.
          </div>
          <div className="flex space-x-4">
            <a href="https://www.facebook.com/people/Pleeso/61552357269954/" className="text-gray-600 hover:text-[#FC9723]"><Facebook size={30} /></a>
            {/* <a href="#" className="text-gray-600 hover:text-[#FC9723]"><Twitter size={30} /></a> */}
            <a href="https://www.instagram.com/pleeso_project/" className="text-gray-600 hover:text-[#FC9723]"><Instagram size={30} /></a>
            <a href="https://www.linkedin.com/company/pleeso/" className="text-gray-600 hover:text-[#FC9723]"><Linkedin size={30} /></a>
          </div>
        </div>
      </div>
    </footer>
  );
};

function App() {
  return (
    <RouterProvider router={router} fallbackElement={<p>Initial Load...</p>} />
  );
}

export default App;
