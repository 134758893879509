import { AxiosResponse } from "axios";
import { RestApi } from "../RestApi";

export abstract class PublicApi extends RestApi {
    constructor() {
        super(process.env.REACT_APP_PUBLIC_API!);
    }

    protected async post(path: string, data: any, headers: any = {}): Promise<AxiosResponse> {
        return super.post(path, data, headers);
    }
}