import { useNavigate } from "react-router-dom";

// Définition des destinations possibles
export type ButtonDestination = 'waitlist' | 'contact' | 'blog' | 'home';

// Mise à jour des props du composant
interface AccessButtonProps {
    className?: string;
    variant?: 'primary' | 'secondary' | 'outline';
    destination: ButtonDestination;
    children?: React.ReactNode;
}

// Configuration des routes et leurs textes par défaut
export const routeConfig: Record<ButtonDestination, {
    path: string;
    defaultText: string;
}> = {
    waitlist: {
        path: '/waitlist',
        defaultText: "Demande d'accès →"
    },
    contact: {
        path: '/contact',
        defaultText: 'Contactez-nous →'
    },
    blog: {
        path: '/blog',
        defaultText: 'Voir le blog →'
    },
    home: {
        path: '/',
        defaultText: 'Accueil →'
    }
};

// Styles des variants
const variants = {
    primary: 'bg-[#333697] hover:bg-[#FC9723]',
    secondary: 'bg-[#FC9723] hover:bg-[#E3871F]',
    outline: 'border-2 border-[#333697] text-[#333697] hover:bg-[#333697] hover:text-white'
};

export const AccessButton: React.FC<AccessButtonProps> = ({
    className = '',
    variant = 'primary',
    destination,
    children
}) => {
    const navigate = useNavigate();
    const baseStyles = "inline-block px-4 py-2.5 text-white rounded-full transition-colors duration-200";

    const buttonText = children || routeConfig[destination].defaultText;

    const handleNavigate = () => {
        navigate(routeConfig[destination].path);
        window.scrollTo(0, 0); // Scroll to top of the page
    };

    return (
        <button
            onClick={handleNavigate}
            className={`${baseStyles} ${variants[variant]} ${variant === 'outline' ? '' : 'text-white'
                } ${className}`}
        >
            {buttonText}
        </button>
    );
};